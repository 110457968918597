import { ReactNode } from 'react';

import './styles.scss';

type ModalTitleProps = {
  children: ReactNode;
};

export function ModalTitle({ children }: ModalTitleProps) {
  return <strong className="modal-title">{children}</strong>;
}
