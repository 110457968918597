import React, { createContext, useState, useContext } from 'react';

import { Defeito } from 'types';

interface ProblemsContextData {
  problems: Defeito[];
  setProblems: React.Dispatch<React.SetStateAction<Defeito[]>>;
}

const ProblemsContext = createContext<ProblemsContextData>(
  {} as ProblemsContextData,
);

const ProblemsProvider: React.FC = ({ children }) => {
  const [problems, setProblems] = useState<Defeito[]>([]);

  return (
    <ProblemsContext.Provider
      value={{
        problems,
        setProblems,
      }}
    >
      {children}
    </ProblemsContext.Provider>
  );
};

function useProblems(): ProblemsContextData {
  const context = useContext(ProblemsContext);

  return context;
}

export { ProblemsProvider, useProblems };
