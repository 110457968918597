type IResponse = {
  value: string;
  label: string;
};

function getDefaultStatusOption(option: any): IResponse | null {
  switch (option.status) {
    case 'A': {
      return {
        value: 'A',
        label: 'Aprovado',
      };
    }
    case 'R': {
      return {
        value: 'R',
        label: 'Reprovado',
      };
    }
    case 'P': {
      return {
        value: 'P',
        label: 'Pendente',
      };
    }
    default: {
      return null;
    }
  }
}

export { getDefaultStatusOption };
