/* eslint-disable import/no-duplicates */

import { useCallback, useEffect, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { Higher, NavigationTree, Page, BaseButton } from 'components';

import { api } from 'services';

import { useAuth, useScreens } from 'hooks';

import { Modulo, Projeto, Programa } from 'types';

import { getDefaultStatusOption } from 'utils';
import { CreateScreenModal } from './CreateScreenModal';

import { EditScreenModal } from './EditScreenModal';

import { DeleteScreenModal } from './DeleteScreenModal';

interface Params {
  id: string;
}

export function Screens() {
  const { id } = useParams<Params>();

  const history = useHistory();

  const [loadingProject, setLoadingProject] = useState(true);
  const [loadingModule, setLoadingModule] = useState(true);
  const [loadingScreens, setLoadingScreens] = useState(true);

  const [module, setModule] = useState<Modulo>({} as Modulo);
  const [project, setProject] = useState<Projeto>({} as Projeto);

  const [selectedScreen, setSelectedScreen] = useState<Programa>(
    {} as Programa,
  );

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { screens, setScreens } = useScreens();
  const { signOut } = useAuth();

  const toggleCreateModalOpen = useCallback(() => {
    setIsCreateModalOpen(state => !state);
  }, []);

  const toggleEditModalOpen = useCallback(() => {
    setIsEditModalOpen(state => !state);
  }, []);

  const toggleDeleteModalOpen = useCallback(() => {
    setIsDeleteModalOpen(state => !state);
  }, []);

  useEffect(() => {
    document.title = 'Programas - Test Soft';

    api
      .get(`screens/${id}`)
      .then(response => {
        setScreens(response.data);
      })
      .finally(() => setLoadingScreens(false));
  }, [id, setScreens, signOut]);

  useEffect(() => {
    api
      .get(`module/${id}`)
      .then(response => {
        setModule(response.data);
      })
      .catch(err => {
        if (err.response?.data.newTokenRequired) {
          toast.error('Sessão expirada!');
          signOut();
          setLoadingModule(false);
        }
      })
      .finally(() => {
        setLoadingModule(false);
      });
  }, [id, signOut]);

  useEffect(() => {
    if (!module.id_projeto_cenario_teste) {
      return;
    }

    api
      .get(`/project/${module.id_projeto_cenario_teste}`)
      .then(response => {
        setProject(response.data);
      })
      .finally(() => {
        setLoadingProject(false);
      });
  }, [module.id_projeto_cenario_teste]);

  return (
    <Page loading={loadingModule || loadingProject || loadingScreens}>
      <Higher title="Programas" />

      {project.descr_projeto && (
        <NavigationTree>
          <Link to={`/modules/${project.id}`}>
            <span>{project.descr_projeto}</span>
          </Link>
          <FiChevronRight />
          <Link to={`/screens/${module.id}`}>
            <span>{module.descr_modulo}</span>
          </Link>
        </NavigationTree>
      )}

      <CreateScreenModal
        moduleId={id}
        isOpen={isCreateModalOpen}
        toggleOpen={toggleCreateModalOpen}
      />

      <EditScreenModal
        isOpen={isEditModalOpen}
        toggleOpen={toggleEditModalOpen}
        screen={selectedScreen}
        setSelectedScreen={setSelectedScreen}
      />

      <DeleteScreenModal
        isOpen={isDeleteModalOpen}
        toggleOpen={toggleDeleteModalOpen}
        screenId={selectedScreen.id}
      />

      <div className="tools-buttons">
        <BaseButton
          model="plus"
          text
          onClick={() => {
            toggleCreateModalOpen();
          }}
        />

        <BaseButton
          model="edit"
          text
          onClick={() => {
            if (!selectedScreen.id) {
              toast.error('Selecione algum registro!');
            } else {
              toggleEditModalOpen();
            }
          }}
          disabled={!selectedScreen.id}
        />
        <BaseButton
          model="delete"
          text
          onClick={() => {
            if (!selectedScreen.id) {
              toast.error('Selecione algum registro!');
            } else {
              toggleDeleteModalOpen();
            }
          }}
          disabled={!selectedScreen.id}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Nome do programa</th>
              <th>Descrição do programa</th>
              <th>Status</th>
              <th>Atualizado há</th>
              <th>Acessar</th>
            </tr>
          </thead>

          <tbody>
            {screens.map(screen => (
              <tr
                key={screen.id}
                onClick={() => {
                  if (selectedScreen.id === screen.id) {
                    setSelectedScreen({} as Programa);
                  } else {
                    setSelectedScreen(screen);
                  }
                }}
                onDoubleClick={() => history.push(`/scenarios/${screen.id}`)}
                className={
                  screen.id === selectedScreen.id
                    ? 'selected-row'
                    : 'not-selected-row'
                }
              >
                <td>{screen.cod_programa}</td>
                <td>{screen.descr_programa}</td>
                <td
                  style={{
                    color:
                      (screen.status === 'A' && 'var(--success)') ||
                      (screen.status === 'R' && 'var(--alert)') ||
                      'var(--accent)',
                  }}
                >
                  {getDefaultStatusOption(screen)?.label || 'Não definido'}
                </td>
                <td>
                  {screen.data_atualizacao &&
                    formatDistance(
                      new Date(),
                      new Date(screen.data_atualizacao),
                      {
                        locale: ptBR,
                      },
                    )}
                </td>
                <td>
                  <BaseButton
                    model="upRight"
                    onClick={() => history.push(`/scenarios/${screen.id}`)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {screens.length <= 0 && (
          <p style={{ marginTop: 30 }}>Não há registros cadastrados!</p>
        )}
      </div>
    </Page>
  );
}
