import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { AuthProvider } from './auth';
import { StepsProvider } from './steps';
import { ScreenProvider } from './screens';
import { ScenariosProvider } from './scenarios';
import { VersionProvider } from './versions';
import { ProblemsProvider } from './problems';
import { SolucoesProvider } from './solucoes.jsx';
import { ColaboradoresProvider } from './colaboradores.jsx';
import { VersoesProvider } from './versoes.jsx';
import { AnosProvider } from './anos.jsx';

const AppProvider: React.FC = ({ children }) => (
  <DndProvider backend={HTML5Backend}>
    <ScreenProvider>
      <ScenariosProvider>
        <VersionProvider>
          <ProblemsProvider>
            <SolucoesProvider>
              <VersoesProvider>
                <ColaboradoresProvider>
                  <AnosProvider>
                    <StepsProvider>
                      <AuthProvider>{children}</AuthProvider>
                    </StepsProvider>
                  </AnosProvider>
                </ColaboradoresProvider>
              </VersoesProvider>
            </SolucoesProvider>
          </ProblemsProvider>
        </VersionProvider>
      </ScenariosProvider>
    </ScreenProvider>
  </DndProvider>
);

export { AppProvider };
