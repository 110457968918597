import { FormHandles } from '@unform/core';
import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import {
  BaseButton,
  Input,
  Modal,
  SelectWithUnform as Select,
  Line,
} from 'components';

import { api } from 'services';

import { useVersions } from 'hooks';

import { OrdemServico } from 'types';

import { getValidationErrors } from 'utils';

import { Buttons } from 'components/Modal/styles';

import { Title, Subtitle } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  selectedVersion: OrdemServico;
  setSelectedVersion: React.Dispatch<
    React.SetStateAction<OrdemServico | undefined>
  >;
}

interface DuplicateServiceOrderFormData {
  nro_ordem: string;
  version_id: number;
}

const DuplicateServiceOrderModal: React.FC<ModalProps> = ({
  isOpen,
  toggleOpen,
  selectedVersion,
  setSelectedVersion,
}) => {
  const formRef = useRef<FormHandles>(null);

  const { setVersions, versions } = useVersions();

  const handleSubmit = useCallback(
    async (data: DuplicateServiceOrderFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nro_ordem: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post('/versions', {
          nro_ordem: data.nro_ordem,
          id: data.version_id,
        });

        setSelectedVersion(response.data);

        setVersions(state => [response.data, ...state]);

        toggleOpen();

        toast.success('Ordem de serviço duplicada com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error('Falha ao duplicar ordem de serviço!');
      }
    },
    [setVersions, toggleOpen, setSelectedVersion],
  );

  const ordemOptions = useMemo(() => {
    return versions.map(version => ({
      value: version.id,
      label: version.nro_ordem,
    }));
  }, [versions]);

  const defaultOrdemOption = useMemo(() => {
    if (selectedVersion.id) {
      return {
        value: selectedVersion.id,
        label: selectedVersion.nro_ordem,
      };
    }
    return null;
  }, [selectedVersion]);

  return (
    <Modal isOpen={isOpen}>
      <Title>Duplicar ordem de serviço</Title>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Subtitle>Nova ordem de serviço</Subtitle>
        <Input
          name="nro_ordem"
          placeholder="Código da nova ordem de serviço"
          type="number"
        />

        <Subtitle>Ordem de serviço base</Subtitle>

        <Select
          name="version_id"
          options={ordemOptions}
          isSearchable
          defaultValue={defaultOrdemOption}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />

        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="plus" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
};

export { DuplicateServiceOrderModal };
