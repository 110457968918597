import React, { createContext, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import produce from 'immer';

import { Passo } from 'types';

import { api } from 'services';

interface StepsContextData {
  move(from: number, to: number): void;
  steps: Passo[];
  setSteps: React.Dispatch<React.SetStateAction<Passo[]>>;
  getNextOrder: () => number;
  handleAddStep: (
    version_id: number | null,
    descr_etapa: string,
  ) => Promise<Passo | undefined>;
}

const StepsContext = createContext<StepsContextData>({} as StepsContextData);

const StepsProvider: React.FC = ({ children }) => {
  const [steps, setSteps] = useState<Passo[]>([]);

  const move = useCallback(
    (from, to) => {
      setSteps(
        produce(steps, draft => {
          /* eslint-disable no-param-reassign */
          const aux = draft[from].ordem_etapa;
          draft[from].ordem_etapa = draft[to].ordem_etapa;
          draft[to].ordem_etapa = aux;
          const dragged = draft[from];

          draft.splice(from, 1);
          draft.splice(to, 0, dragged);

          draft.forEach((d, index) => {
            draft[index] = {
              ...d,
              ordem_etapa: index + 1,
            };
          });
        }),
      );
    },
    [setSteps, steps],
  );

  const getNextOrder = useCallback(() => {
    let order;
    order = steps.findIndex((step, index) => step.ordem_etapa !== index + 1);

    switch (order) {
      case -1: {
        order = steps[steps.length - 1]?.ordem_etapa + 1 || 1;
        break;
      }
      default: {
        order += 1;
        break;
      }
    }

    return order;
  }, [steps]);

  const handleAddStep = useCallback(
    async (
      version_id: number | null,
      descr_etapa: string,
    ): Promise<Passo | undefined> => {
      if (!version_id) {
        toast.error('Selecione alguma ordem de serviço!');
        return;
      }

      const order = getNextOrder();

      const res = await api.post('steps', {
        descr_etapa: descr_etapa || 'Novo passo',
        id_ordem_cenario_teste: version_id,
        ordem_etapa: order,
      });

      const response = await api.get(`steps/${version_id}`);

      setSteps(response.data);

      return res.data; // eslint-disable-line
    },
    [setSteps, getNextOrder],
  );

  return (
    <StepsContext.Provider
      value={{
        move,
        steps,
        setSteps,
        getNextOrder,
        handleAddStep,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};

function useSteps(): StepsContextData {
  const context = useContext(StepsContext);

  return context;
}

export { StepsProvider, useSteps };
