/* eslint-disable import/no-duplicates */

import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useHistory } from 'react-router-dom';

import { Higher, Page, BaseButton } from 'components';

import { api } from 'services';

import { Projeto } from 'types';

import { useAuth } from 'hooks';

import { getDefaultStatusOption } from 'utils';
import { CreateProjectModal } from './CreateProjectModal';

import { EditProjectModal } from './EditProjectModal';

import { DeleteProjectModal } from './DeleteProjectModal';

export function Projects() {
  const history = useHistory();

  const [loadingProjects, setLoadingProjects] = useState(true);

  const [projects, setProjects] = useState<Projeto[]>([]);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [selectedProject, setSelectedProject] = useState<Projeto | undefined>(
    undefined,
  );

  const { signOut } = useAuth();

  const toggleCreateModalOpen = useCallback(() => {
    setIsCreateModalOpen(state => !state);
  }, []);

  const toggleEditModalOpen = useCallback(() => {
    setIsEditModalOpen(state => !state);
  }, []);

  const toggleDeleteModalOpen = useCallback(() => {
    setIsDeleteModalOpen(state => !state);
  }, []);

  useEffect(() => {
    document.title = 'Projetos - Test Soft';

    api
      .get('projects')
      .then(response => {
        setProjects(response.data);
      })
      .catch(err => {
        if (err.response?.data.newTokenRequired) {
          signOut();
          toast.error('Sessão expirada!');
        }
      })
      .finally(() => {
        setLoadingProjects(false);
      });
  }, [signOut]);

  return (
    <Page loading={loadingProjects}>
      <Higher title="Projetos" />

      <CreateProjectModal
        isOpen={isCreateModalOpen}
        toggleOpen={toggleCreateModalOpen}
        setProjects={setProjects}
      />

      {selectedProject && (
        <>
          <EditProjectModal
            isOpen={isEditModalOpen}
            toggleOpen={toggleEditModalOpen}
            project={selectedProject}
            setProjects={setProjects}
            setSelectedProject={setSelectedProject}
          />

          <DeleteProjectModal
            isOpen={isDeleteModalOpen}
            toggleOpen={toggleDeleteModalOpen}
            projectId={selectedProject.id}
            setProjects={setProjects}
            setSelectedProject={setSelectedProject}
          />
        </>
      )}

      <div className="tools-buttons">
        <BaseButton
          model="plus"
          text
          onClick={() => {
            toggleCreateModalOpen();
          }}
        />

        <BaseButton
          model="edit"
          text
          onClick={toggleEditModalOpen}
          disabled={!selectedProject}
        />
        <BaseButton
          model="delete"
          text
          onClick={toggleDeleteModalOpen}
          disabled={!selectedProject}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Nome do projeto</th>
              <th>Status</th>
              <th>Atualizado há</th>
              <th>Acessar</th>
            </tr>
          </thead>

          <tbody>
            {projects.map(project => (
              <tr
                key={project.id}
                onClick={() => {
                  if (selectedProject?.id === project.id) {
                    setSelectedProject(undefined);
                  } else {
                    setSelectedProject(project);
                  }
                }}
                onDoubleClick={() => history.push(`modules/${project.id}`)}
                className={
                  project.id === selectedProject?.id
                    ? 'selected-row'
                    : 'not-selected-row'
                }
              >
                <td>{project.descr_projeto}</td>
                <td
                  style={{
                    color:
                      (project.status === 'A' && 'var(--success)') ||
                      (project.status === 'R' && 'var(--alert)') ||
                      'var(--accent)',
                  }}
                >
                  {getDefaultStatusOption(project)?.label || 'Não definido'}
                </td>
                <td>
                  {project.data_atualizacao &&
                    formatDistance(
                      new Date(),
                      new Date(project.data_atualizacao),
                      {
                        locale: ptBR,
                      },
                    )}
                </td>
                <td>
                  <BaseButton
                    model="upRight"
                    onClick={() => history.push(`modules/${project.id}`)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {projects.length <= 0 && (
          <p style={{ marginTop: 30 }}>Não há registros cadastrados!</p>
        )}
      </div>
    </Page>
  );
}
