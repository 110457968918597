import { OrdemServico } from 'types';

function getMaintenanceOption(ordemServico: OrdemServico | undefined): string {
  switch (ordemServico?.exige_atualizacao_teste) {
    case 'S':
      return 'Sim';
    case 'N':
      return 'Não';
    default: {
      return 'undefined';
    }
  }
}

export { getMaintenanceOption };
