import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { BaseButton, Input, Line, Modal, Title, Loading } from 'components';

import { Buttons } from 'components/Modal/styles';

import { api } from 'services';

import { getValidationErrors } from 'utils';

import { COLORS } from 'styles';

import { Programs } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  setInputValue:
    | ((fieldName: string, value: string) => boolean | void)
    | undefined;
}

const SearchScreenModal: React.FC<ModalProps> = ({
  isOpen,
  toggleOpen,
  setInputValue,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [programs, setProgramas] = useState<
    { cod_programa: string; descr_programa: string }[]
  >([]);

  const handleSubmit = useCallback(async data => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required().min(3),
      });

      await schema.validate(data, { abortEarly: false });

      const response = await api.get(`/programas/?name=${data.name}`);

      setProgramas(response.data);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        toast.error('Verifique os campos em vermelho!');

        return;
      }

      toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} width={600}>
      <Title>Programas</Title>

      <Form ref={formRef} onSubmit={handleSubmit} style={{ marginTop: 15 }}>
        <div style={{ display: 'flex' }}>
          <Input
            name="name"
            style={{ maxHeight: 20 }}
            placeholder="Código do programa"
            uppercase
          />
          <BaseButton model="search" type="submit" style={{ marginLeft: 10 }} />
        </div>
      </Form>

      <Line />
      <Programs>
        {loading ? (
          <Loading color={COLORS.WHITE} />
        ) : (
          <>
            {programs.length <= 0 && (
              <span style={{ padding: 10 }}>Nenhum item encontrado!</span>
            )}
            {programs.map(program => (
              <button
                type="button"
                key={program.cod_programa}
                onClick={() => {
                  if (setInputValue) {
                    setInputValue('cod_programa', program.cod_programa);
                    setInputValue('descr_programa', program.descr_programa);
                    toggleOpen();
                  }
                }}
              >
                <span> {program.cod_programa}</span>
                <span> {program.descr_programa}</span>
              </button>
            ))}
          </>
        )}
      </Programs>

      <Line />

      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />
      </Buttons>
    </Modal>
  );
};
export { SearchScreenModal };
