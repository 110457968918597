import { useRef, RefObject, useState, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { BaseButton, CreateProblemModal, Textarea } from 'components';

import { api } from 'services';

import { useSteps } from 'hooks';

import { Passo } from 'types';

import { getValidationErrors } from 'utils';

import { COLORS } from 'styles';

import { Container } from './styles';

type StepProps = {
  step: Passo;
  index: number;
  viewMode: boolean;
  isEditing: boolean;
  setIsEditingSteps?: React.Dispatch<React.SetStateAction<Passo[]>>;
};

type ItemProps = {
  type: string;
  index: number;
};

export function Step({
  step,
  index,
  viewMode,
  isEditing,
  setIsEditingSteps,
}: StepProps) {
  const formRef = useRef<FormHandles>(null);
  const comunRef = useRef(null);
  const ref = useRef() as RefObject<HTMLDivElement>;
  const { move, setSteps, steps } = useSteps();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const toggleCreateModalOpen = useCallback(() => {
    setIsCreateModalOpen(state => !state);
  }, []);

  const handleDeleteStep = useCallback(async () => {
    try {
      await api.delete(`/steps/${step.id}`);

      setSteps(steps.filter(filteredStep => filteredStep.id !== step.id));
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }, [setSteps, step.id, steps]);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'STEP', index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'STEP',
    async drop() {
      await api.put('/steps/save', {
        steps,
      });
    },
    async hover(item: ItemProps, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current?.getBoundingClientRect();
      if (targetSize) {
        const targetCenter = (targetSize.bottom - targetSize.top) / 2;

        const draggedOfSet = monitor.getClientOffset();
        if (draggedOfSet) {
          const draggedTop = draggedOfSet?.y - targetSize.top;

          if (draggedIndex < targetIndex && draggedTop < targetCenter) {
            return;
          }

          if (draggedIndex > targetIndex && draggedTop > targetCenter) {
            return;
          }

          /* eslint-disable no-param-reassign */
          item.index = targetIndex;

          move(draggedIndex, targetIndex);
        }
      }
    },
  });

  dragRef(dropRef(ref));

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_etapa: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/steps/${step.id}`, {
          descr_etapa: data.descr_etapa,
        });
        const newSteps = steps.map(mappedStep => {
          if (mappedStep.id === step.id) {
            return response.data;
          }
          return mappedStep;
        });

        setSteps(newSteps);

        if (setIsEditingSteps) {
          setIsEditingSteps(state =>
            state.filter(s => s.id !== response.data.id),
          );
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Preencha o campo de descrição!');
        }

        toast.error('Não foi possível atualizar essa etapa!');
      }
    },
    [step.id, setSteps, steps, setIsEditingSteps],
  );

  return (
    <Container
      ref={!viewMode ? ref : comunRef}
      isDragging={isDragging}
      isEditing={isEditing}
      viewMode={viewMode}
    >
      <CreateProblemModal
        isOpen={isCreateModalOpen}
        toggleOpen={toggleCreateModalOpen}
        versionId={step.id_ordem_cenario_teste}
        stepId={step.id}
      />

      {isEditing ? (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={step}>
          <Textarea name="descr_etapa" containerStyle={{ maxWidth: '80%' }} />

          <div>
            <BaseButton
              model="check"
              color={COLORS.SUCCESS}
              type="submit"
              text={false}
            />
            <BaseButton
              model="close"
              onClick={() => {
                if (setIsEditingSteps) {
                  setIsEditingSteps(state =>
                    state.filter(s => s.id !== step.id),
                  );
                }
              }}
              text={false}
            />
          </div>
        </Form>
      ) : (
        <>
          <p>
            {step.ordem_etapa}: {step.descr_etapa}
          </p>

          <div>
            {!viewMode && (
              <>
                <BaseButton
                  model="edit"
                  text={false}
                  onClick={() => {
                    if (setIsEditingSteps) {
                      setIsEditingSteps(state => [...state, step]);
                    }
                  }}
                />
                <BaseButton
                  model="delete"
                  text={false}
                  onClick={handleDeleteStep}
                />
              </>
            )}

            <BaseButton
              model="problem"
              text={false}
              onClick={toggleCreateModalOpen}
            />
          </div>
        </>
      )}
    </Container>
  );
}
