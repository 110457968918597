/* eslint-disable import/no-duplicates */

import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { api } from 'services';

import {
  CreateProblemModal,
  Page,
  Higher,
  NavigationTree,
  BaseButton,
} from 'components';

import {
  OrdemServico,
  Defeito,
  Cenario,
  Programa,
  Modulo,
  Projeto,
} from 'types';

import { useSteps } from 'hooks';

import { getDefaultGravityOption, getDefaultStatusOption } from 'utils';

import { ViewProblemModal } from './ViewProblemModal';

import { EditProblemModal } from './EditProblemModal';

import { DeleteProblemModal } from './DeleteProblemModal';

interface Params {
  id: string;
}

export function Problems() {
  const { id } = useParams<Params>();
  const [problems, setProblems] = useState<Defeito[]>([]);
  const [selectedProblem, setSelectedProblem] = useState<Defeito | undefined>(
    undefined,
  );

  const [project, setProject] = useState<Projeto>({} as Projeto);
  const [module, setModule] = useState<Modulo>({} as Modulo);
  const [screen, setScreen] = useState<Programa>({} as Programa);
  const [scenario, setScenario] = useState<Cenario>({} as Cenario);
  const [version, setVersion] = useState<OrdemServico>({} as OrdemServico);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { setSteps, steps } = useSteps();

  const toggleCreateModalOpen = useCallback(() => {
    setIsCreateModalOpen(state => !state);
  }, []);

  const toggleEditModalOpen = useCallback(() => {
    setIsEditModalOpen(state => !state);
  }, []);

  const toggleViewModalOpen = useCallback(() => {
    setIsViewModalOpen(state => !state);
  }, []);

  const toggleDeleteModalOpen = useCallback(() => {
    setIsDeleteModalOpen(state => !state);
  }, []);

  useEffect(() => {
    if (!module.id_projeto_cenario_teste) {
      return;
    }
    api.get(`project/${module.id_projeto_cenario_teste}`).then(response => {
      setProject(response.data);
    });
  }, [module.id_projeto_cenario_teste]);

  useEffect(() => {
    if (!screen.id_modulo_cenario_teste) {
      return;
    }

    api.get(`module/${screen.id_modulo_cenario_teste}`).then(response => {
      setModule(response.data);
    });
  }, [screen.id_modulo_cenario_teste]);

  useEffect(() => {
    if (!scenario.id_programa_cenario_teste) {
      return;
    }

    api.get(`screen/${scenario.id_programa_cenario_teste}`).then(response => {
      setScreen(response.data);
    });
  }, [scenario.id_programa_cenario_teste]);

  useEffect(() => {
    if (!version.id_cenario_teste) {
      return;
    }

    api.get(`scenario/${version.id_cenario_teste}`).then(response => {
      setScenario(response.data);
    });
  }, [version.id_cenario_teste]);

  useEffect(() => {
    document.title = 'Gestão de Defeitos - Test Soft';

    api.get(`/version/${id}`).then(response => {
      setVersion(response.data);
    });
  }, [id]);

  useEffect(() => {
    api.get(`/steps/${id}`).then(response => {
      setSteps(response.data);
    });
  }, [id, setSteps]);

  useEffect(() => {
    api.get(`problems/${id}`).then(response => {
      setProblems(response.data);
    });
  }, [id]);

  return (
    <Page>
      <Higher title="Gestão de Defeitos" />

      {project.descr_projeto && (
        <NavigationTree>
          <Link to={`/modules/${project.id}`}>
            <span>{project.descr_projeto}</span>
          </Link>
          <FiChevronRight />
          <Link to={`/screens/${module.id}`}>
            <span>{module.descr_modulo}</span>
          </Link>
          <FiChevronRight />
          <Link to={`/scenarios/${screen.id}`}>
            <span>{screen.cod_programa}</span>
          </Link>
          <FiChevronRight />
          <span>{scenario.descr_cenario}</span>
          <FiChevronRight />
          <span>{version.nro_ordem}</span>
        </NavigationTree>
      )}

      <CreateProblemModal
        isOpen={isCreateModalOpen}
        toggleOpen={toggleCreateModalOpen}
        versionId={version.id}
      />

      {selectedProblem && (
        <>
          <EditProblemModal
            isOpen={isEditModalOpen}
            toggleOpen={toggleEditModalOpen}
            setProblems={setProblems}
            problem={selectedProblem}
            setSelectedProblem={setSelectedProblem}
          />

          <ViewProblemModal
            isOpen={isViewModalOpen}
            toggleOpen={toggleViewModalOpen}
            problem={selectedProblem}
          />

          <DeleteProblemModal
            isOpen={isDeleteModalOpen}
            toggleOpen={toggleDeleteModalOpen}
            problemId={selectedProblem.id}
            setProblems={setProblems}
            setSelectedProblem={setSelectedProblem}
          />
        </>
      )}

      <div className="tools-buttons">
        <BaseButton
          model="eye"
          text
          onClick={toggleViewModalOpen}
          disabled={!selectedProblem}
        />

        <BaseButton model="plus" text onClick={toggleCreateModalOpen} />

        <BaseButton
          model="edit"
          text
          onClick={toggleEditModalOpen}
          disabled={!selectedProblem}
        />
        <BaseButton
          model="delete"
          text
          onClick={toggleDeleteModalOpen}
          disabled={!selectedProblem}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Gravidade</th>
              <th>Passo</th>
              <th>Status</th>
              <th>Retorno Desenvolvedor</th>
              <th>Atualizado há</th>
            </tr>
          </thead>

          <tbody>
            {problems.map(problem => (
              <tr
                key={problem.id}
                onClick={() => {
                  if (selectedProblem?.id === problem.id) {
                    setSelectedProblem(undefined);
                  } else {
                    setSelectedProblem(problem);
                  }
                }}
                className={
                  problem.id === selectedProblem?.id
                    ? 'selected-row'
                    : 'not-selected-row'
                }
              >
                <td>{problem.descr_defeito}</td>
                <td>
                  {getDefaultGravityOption(problem)?.label || 'Não definido'}
                </td>
                <td>
                  {steps.find(
                    step => problem.id_etapa_cenario_teste === step.id,
                  )?.descr_etapa || 'Não definido'}
                </td>
                <td
                  style={{
                    color:
                      (problem.status === 'A' && 'var(--success)') ||
                      (problem.status === 'R' && 'var(--alert)') ||
                      'var(--accent)',
                  }}
                >
                  {getDefaultStatusOption(problem)?.label || 'Não definido'}
                </td>
                <td>{problem.descr_retorno}</td>
                <td>
                  {problem.data_atualizacao &&
                    formatDistance(
                      new Date(),
                      new Date(problem.data_atualizacao),
                      {
                        locale: ptBR,
                      },
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {problems.length <= 0 && (
          <p style={{ marginTop: 30 }}>Não há problemas cadastrados!</p>
        )}
      </div>
    </Page>
  );
}
