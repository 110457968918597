import { shade } from 'polished';
import { ButtonHTMLAttributes } from 'react';

import './styles.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: string;
  title: string;
}

export function LargeButton({ color, title, ...rest }: ButtonProps) {
  return (
    <button
      className="large-button"
      type="button"
      style={{ backgroundColor: color, borderColor: shade(0.2, color) }}
      {...rest}
    >
      {title}
    </button>
  );
}
