import React, { createContext, useState, useContext } from 'react';

import { Cenario } from 'types';

interface ScenariosContextData {
  scenarios: Cenario[];
  setScenarios: React.Dispatch<React.SetStateAction<Cenario[]>>;
}

const ScenariosContext = createContext<ScenariosContextData>(
  {} as ScenariosContextData,
);

const ScenariosProvider: React.FC = ({ children }) => {
  const [scenarios, setScenarios] = useState<Cenario[]>([]);

  return (
    <ScenariosContext.Provider
      value={{
        scenarios,
        setScenarios,
      }}
    >
      {children}
    </ScenariosContext.Provider>
  );
};

function useScenarios(): ScenariosContextData {
  const context = useContext(ScenariosContext);

  return context;
}

export { ScenariosProvider, useScenarios };
