import { ReactNode } from 'react';

import './styles.scss';

type SubtitleProps = {
  children: ReactNode;
};

export function Subtitle({ children }: SubtitleProps) {
  return <h2 className="subtitle">{children}</h2>;
}
