/* eslint-disable react/no-array-index-key */

import { ReactNode, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { ModalTitle as Title } from 'components';

import { COLORS } from 'styles';

type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  width?: number;
  title?: string;
};

export function Modal({ children, isOpen, width, title }: ModalProps) {
  const [modalStatus, setModalStatus] = useState(isOpen);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={modalStatus}
      ariaHideApp={false}
      portalClassName="modal"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          background: COLORS.BACKGROUND,
          color: '#000000',
          borderRadius: '8px',
          width: '100%',
          maxWidth: width || '450px',
          maxHeight: '95vh',
          border: '0',
          overflowY: 'auto',
        },
        overlay: {
          backgroundColor: 'rgba(18, 18, 20, 0.6)',
        },
      }}
    >
      {title && <Title>{title}</Title>}
      {children}
    </ReactModal>
  );
}
