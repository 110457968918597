import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  uppercase?: boolean;
}

export function Input({
  containerStyle,
  name,
  uppercase = false,
  ...rest
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, error, defaultValue, registerField } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const toInputUppercase = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = `${e.target.value}`.toUpperCase();
  };

  return (
    <Container style={containerStyle} isErrored={!!error} isFocused={isFocused}>
      <input
        defaultValue={defaultValue}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        ref={inputRef}
        onInput={uppercase ? toInputUppercase : undefined}
        {...rest}
      />
    </Container>
  );
}
