import React, { createContext, useState, useContext } from 'react';

const VersoesContext = createContext();

const VersoesProvider = ({ children }) => {
  const [versoes, setVersoes] = useState([]);

  return (
    <VersoesContext.Provider
      value={{
        versoes,
        setVersoes,
      }}
    >
      {children}
    </VersoesContext.Provider>
  );
};

function useVersoes() {
  const context = useContext(VersoesContext);

  return context;
}

export { VersoesProvider, useVersoes };
