import { Passo } from 'types';

interface IResponse {
  value: number;
  label: string;
}

function getDefaultStepOption(
  steps: Passo[],
  step_id: number | undefined,
): IResponse | null {
  if (!step_id) {
    return null;
  }

  const [defaultStep] = steps.filter(step => step.id === step_id && step);

  return {
    label: `${defaultStep.ordem_etapa}: ${defaultStep.descr_etapa}`,
    value: defaultStep.id,
  };
}

export { getDefaultStepOption };
