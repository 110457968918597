/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useColaboradores } from 'hooks/colaboradores';
import { useSolucoes } from 'hooks/solucoes';
import { useAnos } from 'hooks/anos';
import { useVersoes } from 'hooks/versoes';
import Modal from 'react-modal';
import { CSVLink } from 'react-csv';
import { BaseButton } from 'components/Buttons/BaseButton/index.js';
import { api } from 'services/api.jsx';
import { getReadableDate } from 'utils/metrics.jsx';
import ChartCustomTooltip from 'components/ChartCustomTooltip/index.jsx';

import {
  ModalBlankZone,
  ModalWindowZone,
  ModalButtonContainer,
  ModalInputContainer,
  ModalChart,
  ModalTitle,
} from 'components/ChartModal/styles.jsx';

import {
  Component,
  Container,
  TitleWrapper,
  ButtonFilter,
  ButtonReportPdf,
  ButtonReportExcel,
  ButtonGoBackReport,
  ReportDetail,
  ReportDetailInfo,
  ReportDetailTotal,
  ContainerGrafico,
  ContainerGraficoChild,
} from '../Chart/styles.jsx';
import ChartTitle from '../ChartTitle/index.jsx';

import ContentNotFound from '../ContentNotFound/index.jsx';

import ReportSolicitacaoAtrasada from '../../reports/ReportSolicitacaoAtrasada.jsx';

function ChartPrazoEntrega(props) {
  const { solucoes } = useSolucoes();
  const { anos } = useAnos();
  const { versoes } = useVersoes();
  const { colaboradores } = useColaboradores();

  // Dados dos gráficos
  const [etapaAtual, setEtapaAtual] = useState(1);
  const [dadosGraficoEtapa1, setDadosGraficoEtapa1] = useState(); // solução
  const [dadosGraficoEtapa2, setDadosGraficoEtapa2] = useState(); // versão
  const [dadosGraficoEtapa3, setDadosGraficoEtapa3] = useState(); // tipo serviço(bug,melhoria,customização)
  const [dadosGraficoEtapa4, setDadosGraficoEtapa4] = useState(); // colaborador
  const [csvData, setCsvData] = useState();

  // Controle de exibição modal de filtro
  const [modalIsOpen, setIsOpen] = useState(false);

  const [exibirAvisoNaoEncontrado, setExibirAvisoNaoEncontrado] = useState(
    false,
  );

  const getFiltrosPadrao = () => {
    return {
      ano: new Date().getFullYear(),
      dataInicial: '',
      dataFinal: '',
      solucao: '',
      colaborador: '',
      versao: '',
      emAberto: '',
    };
  };

  const [formValues, setFormValues] = useState(getFiltrosPadrao());
  const [filterValues, setFilterValues] = useState(getFiltrosPadrao());

  const getFiltros = () => {
    return {
      ano: filterValues.ano,
      dataInicial: filterValues.dataInicial,
      dataFinal: filterValues.dataFinal,
      solucao: filterValues.solucao,
      colaborador: filterValues.colaborador,
      versao: filterValues.versao,
      emAberto: filterValues.emAberto,
    };
  };

  const handleChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Dados exibidos no ReportDetail
  const [rdAno, setRdAno] = useState('');
  const [rdSolucao, setRdSolucao] = useState('');
  const [rdColaborador, setRdColaborador] = useState('');
  const [rdVersao, setRdVersao] = useState('');
  const [rdDatas, setRdDatas] = useState('');
  const [rdTipoServico, setRdTipoServico] = useState('');
  const [rdEmAberto, setRdEmAberto] = useState('');
  const [total, setTotal] = useState('');

  const getTotal = data => {
    let count = 0;
    if (data) {
      data.map(v => {
        count += v.SOLICITACOES;
      });
    }
    return count;
  };

  const defineReportDetail = () => {
    setRdAno(filterValues.ano);
    setRdSolucao(filterValues.solucao);

    if (filterValues.colaborador) {
      colaboradores.map(v => {
        if (v.codigo.toString() === filterValues.colaborador) {
          setRdColaborador(v.colaborador);
        }
      });
    } else {
      setRdColaborador('');
    }
    if (filterValues.dataInicial && !filterValues.dataFinal) {
      setRdDatas(` (desde ${getReadableDate(filterValues.dataInicial)})`);
    }
    if (!filterValues.dataInicial && filterValues.dataFinal) {
      setRdDatas(` (até ${getReadableDate(filterValues.dataFinal)})`);
    }
    if (filterValues.dataInicial && filterValues.dataFinal) {
      setRdDatas(
        ` (${getReadableDate(filterValues.dataInicial)} até ${getReadableDate(
          filterValues.dataFinal,
        )})`,
      );
    }
    if (!filterValues.dataInicial && !filterValues.dataFinal) {
      setRdDatas('');
    }

    setRdEmAberto(filterValues.emAberto === 'S' ? 'Não concluídas' : '');

    if (filterValues.versao && filterValues.solucao) {
      versoes.map(v => {
        if (v.id.toString() === filterValues.versao.toString()) {
          setRdVersao(v.versao);
        }
      });
    } else {
      setRdVersao('');
    }
  };

  const getVersaoFromFiltro = id => {
    let ver = '';

    if (versoes && filterValues.solucao) {
      versoes.forEach(v => {
        if (v.id.toString() === id.toString()) {
          ver = v.versao;
        }
      });
    }
    return ver;
  };

  const consultarGraficoEtapa1 = () => {
    api
      .get('solicitacoesAtrasadas/grafico/etapa1', {
        params: {
          ano: filterValues.ano,
          solucao: filterValues.solucao,
          colaborador: filterValues.colaborador,
          dataInicial: filterValues.dataInicial,
          dataFinal: filterValues.dataFinal,
          versao: getVersaoFromFiltro(filterValues.versao),
          emAberto: filterValues.emAberto,
        },
      })
      .then(response => {
        setExibirAvisoNaoEncontrado(response.data.length === 0);
        setDadosGraficoEtapa1(response.data);
        defineReportDetail();
        setTotal(getTotal(response.data));
      })
      .catch(err => {
        console.log(err);
      });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onClickExibirGraficoEtapa2 = objeto => {
    api
      .get('solicitacoesAtrasadas/grafico/etapa2', {
        params: {
          ano: filterValues.ano,
          solucao: objeto.SOLUCAO,
          dataInicial: filterValues.dataInicial,
          dataFinal: filterValues.dataFinal,
          versao: getVersaoFromFiltro(filterValues.versao),
          colaborador: filterValues.colaborador,
          emAberto: filterValues.emAberto,
        },
      })
      .then(response => {
        setDadosGraficoEtapa2(response.data);
        setRdSolucao(objeto.SOLUCAO);
        setTotal(objeto.SOLICITACOES);
        avancar();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onClickExibirGraficoEtapa3 = objeto => {
    api
      .get('solicitacoesAtrasadas/grafico/etapa3', {
        params: {
          ano: filterValues.ano,
          solucao: rdSolucao,
          dataInicial: filterValues.dataInicial,
          dataFinal: filterValues.dataFinal,
          colaborador: filterValues.colaborador,
          versao: objeto.VERSAO,
          emAberto: filterValues.emAberto,
        },
      })
      .then(response => {
        setDadosGraficoEtapa3(response.data);
        setRdVersao(objeto.VERSAO);
        setTotal(objeto.SOLICITACOES);
        avancar();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onClickExibirGraficoEtapa4 = objeto => {
    api
      .get('solicitacoesAtrasadas/grafico/etapa4', {
        params: {
          ano: filterValues.ano,
          solucao: rdSolucao,
          dataInicial: filterValues.dataInicial,
          dataFinal: filterValues.dataFinal,
          colaborador: filterValues.colaborador,
          versao: rdVersao,
          tipoServico: objeto.TIPO_SERVICO,
          emAberto: filterValues.emAberto,
        },
      })
      .then(response => {
        setDadosGraficoEtapa4(response.data);
        setRdTipoServico(objeto.TIPO_SERVICO);
        setTotal(objeto.SOLICITACOES);
        avancar();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const setFiltroDefinitivo = () => {
    setFilterValues({ ...formValues, teste: Math.random() });
  };

  const filtrarByModal = () => {
    setFiltroDefinitivo();
  };

  /**
   * O useEffect aqui é para que apenas atualize
   * os valores a aplicados como filtro ao clicar em Filtrar.
   * Ao clicar para fechar o form de filtro, os dados selecionados ficam
   * apenas no escopo do form e não dos filtros usados nas consultas.
   * Ainda não sei sobre como resetar os valores informados no form de filtro
   * para voltar a ser os valores já aplicados no gráfico no clique Filtrar realizado anteriormente...
   */
  useEffect(() => {
    setEtapaAtual(1);
    consultarGraficoEtapa1();
    setDadosGraficoEtapa2();
    defineReportDetail();
    closeModal();
  }, [filterValues]);

  const isVersaoSolucao = (solucao, versao) => {
    if (solucao) {
      const s = {
        Akita() {
          return 'Ponto/Akita Soft Enterprise';
        },
        'Akita Mobile': function () {
          return 'Ponto/Akita Soft Mobile';
        },
        GT() {
          return 'GT Soft';
        },
        'Ponto Enterprise': function () {
          return 'Ponto/Akita Soft Enterprise';
        },
        'Ponto Express': function () {
          return 'Ponto Soft Express';
        },
        'Ponto Mobile': function () {
          return 'Ponto/Akita Soft Mobile';
        },
      };

      return s[solucao]() === versao.solucao;
    }
    return false;
  };

  const getStringDetailInfo = () => {
    let s = '';
    if (filterValues.ano) {
      s += `${filterValues.ano}`;
    }

    if (rdDatas) {
      s += ` - ${rdDatas}`;
    }

    if (rdEmAberto) {
      s += ` - ${rdEmAberto}`;
    }

    if (
      (rdSolucao && etapaAtual === 1 && filterValues.solucao === rdSolucao) ||
      (rdSolucao && etapaAtual !== 1)
    ) {
      s += ` - ${rdSolucao}`;
    }

    if (rdVersao && (etapaAtual > 2 || filterValues.versao)) {
      s += ` - ${rdVersao}`;
    }

    if (rdTipoServico && etapaAtual === 4) {
      s += ` - ${rdTipoServico}`;
    }

    if (rdColaborador && filterValues.colaborador) {
      s += ` - ${rdColaborador}`;
    }

    return s;
  };

  const resolveGraficoVisivel = () => {
    if (exibirAvisoNaoEncontrado) return;

    if (etapaAtual === 1) {
      return graficoEtapa1;
    }

    if (etapaAtual === 2) {
      return graficoEtapa2;
    }

    if (etapaAtual === 3) {
      return graficoEtapa3;
    }

    if (etapaAtual === 4) {
      return graficoEtapa4;
    }
  };

  const avancar = () => {
    setEtapaAtual(etapaAtual + 1);
  };

  const voltar = () => {
    setEtapaAtual(etapaAtual - 1);
  };

  const resolveRetrocederEtapa = () => {
    if (etapaAtual === 2) {
      setDadosGraficoEtapa3();
      setTotal(getTotal(dadosGraficoEtapa1));
      voltar();
    }
    if (etapaAtual === 3) {
      setDadosGraficoEtapa3();
      setTotal(getTotal(dadosGraficoEtapa2));
      voltar();
    }
    if (etapaAtual === 4) {
      setDadosGraficoEtapa4();
      setTotal(getTotal(dadosGraficoEtapa3));
      voltar();
    }
  };

  const getNomeColaborador = () => {
    let nome = '';
    colaboradores.forEach(function (v) {
      if (v.codigo.toString() === filterValues.colaborador.toString()) {
        nome = v.colaborador;
      }
    });

    return nome;
  };

  const gerarPDF = () => {
    const filtro = {
      ano: filterValues.ano,
      dataInicial: filterValues.dataInicial,
      dataFinal: filterValues.dataFinal,
      solucao: filterValues.solucao,
      versao: filterValues.versao,
      descritivoVersao: getVersaoFromFiltro(filterValues.versao),
      colaborador: filterValues.colaborador,
      nomeColaborador: getNomeColaborador(filterValues.colaborador),
      emAberto: filterValues.emAberto,
    };

    new ReportSolicitacaoAtrasada(filtro).gerarDocumento();
  };

  const gerarCSV = () => {
    setCsvData();

    api
      .get('solicitacoesAtrasadas/csv', {
        params: {
          ano: filterValues.ano,
          solucao: filterValues.solucao,
          dataInicial: filterValues.dataInicial,
          dataFinal: filterValues.dataFinal,
          versao: getVersaoFromFiltro(filterValues.versao),
          colaborador: filterValues.colaborador,
          emAberto: filterValues.emAberto,
        },
      })
      .then(response => {
        setCsvData(response.data.length > 0 ? response.data : null);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    consultarGraficoEtapa1();
  }, []);

  const graficoEtapa1 = (
    <ContainerGraficoChild>
      <ResponsiveContainer width="99%" height="94%" debounce={0}>
        <BarChart data={dadosGraficoEtapa1} layout="vertical">
          <CartesianGrid strokeOpacity={0.02} />
          <XAxis type="number" allowDecimals={false} tick={{ fontSize: 12 }} />
          <YAxis
            width={130}
            tick={{ fill: 'white', fontSize: 12 }}
            type="category"
            dataKey="SOLUCAO"
          />
          <Tooltip cursor={{ opacity: 0.1 }} content={<ChartCustomTooltip />} />
          <Bar
            isAnimationActive={false}
            style={{ cursor: 'pointer' }}
            /* label={{
              fill: 'white',
              fontSize: 14,
              fontWeight: 'bold',
              position: 'right',
            }} */
            dataKey="SOLICITACOES"
            fill="#fa8828"
            onClick={(a, b, c) => {
              onClickExibirGraficoEtapa2(a, b, c);
            }}
          >
            <LabelList
              dataKey="SOLICITACOES"
              fill="white"
              fontSize={14}
              fontWeight="bold"
              position="right"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </ContainerGraficoChild>
  );

  const graficoEtapa2 = (
    <ContainerGraficoChild>
      <ResponsiveContainer width="99%" height="94%">
        <BarChart data={dadosGraficoEtapa2} layout="vertical">
          <CartesianGrid strokeOpacity={0.1} />
          <XAxis type="number" allowDecimals={false} tick={{ fontSize: 12 }} />
          <YAxis
            width={170}
            tick={{ fill: 'white', fontSize: 12 }}
            type="category"
            dataKey="VERSAO"
          />

          <Tooltip cursor={{ opacity: 0.1 }} content={<ChartCustomTooltip />} />
          <Bar
            isAnimationActive={false}
            style={{ cursor: 'pointer' }}
            label={{
              fill: 'white',
              fontSize: 14,
              fontWeight: 'bold',
              position: 'right',
            }}
            dataKey="SOLICITACOES"
            fill="#fa8828"
            onClick={(a, b, c) => {
              onClickExibirGraficoEtapa3(a, b, c);
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </ContainerGraficoChild>
  );

  const graficoEtapa3 = (
    <ContainerGraficoChild>
      <ResponsiveContainer width="99%" height="94%">
        <BarChart data={dadosGraficoEtapa3} layout="vertical">
          <CartesianGrid strokeOpacity={0.1} />
          <XAxis type="number" allowDecimals={false} tick={{ fontSize: 12 }} />
          <YAxis
            width={170}
            tick={{ fill: 'white', fontSize: 12 }}
            type="category"
            dataKey="TIPO_SERVICO"
          />

          <Tooltip cursor={{ opacity: 0.1 }} content={<ChartCustomTooltip />} />
          <Bar
            isAnimationActive={false}
            style={{ cursor: 'pointer' }}
            label={{
              fill: 'white',
              fontSize: 14,
              fontWeight: 'bold',
              position: 'right',
            }}
            dataKey="SOLICITACOES"
            fill="#fa8828"
            onClick={(a, b, c) => {
              onClickExibirGraficoEtapa4(a, b, c);
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </ContainerGraficoChild>
  );

  const graficoEtapa4 = (
    <ContainerGraficoChild>
      <ResponsiveContainer width="99%" height="94%">
        <BarChart data={dadosGraficoEtapa4} layout="vertical">
          <CartesianGrid strokeOpacity={0.1} />
          <XAxis type="number" allowDecimals={false} tick={{ fontSize: 12 }} />
          <YAxis
            width={170}
            tick={{ fill: 'white', fontSize: 12 }}
            type="category"
            dataKey="NOME_COLABORADOR"
          />

          <Tooltip cursor={{ opacity: 0.1 }} content={<ChartCustomTooltip />} />
          <Bar
            isAnimationActive={false}
            label={{
              fill: 'white',
              fontSize: 14,
              fontWeight: 'bold',
              position: 'right',
            }}
            dataKey="SOLICITACOES"
            fill="#fa8828"
          />
        </BarChart>
      </ResponsiveContainer>
    </ContainerGraficoChild>
  );

  Modal.setAppElement('body');
  return (
    <Container style={props.style}>
      <TitleWrapper>
        <ButtonFilter
          onClick={() => {
            setIsOpen(true);
          }}
        />
        <ButtonReportPdf
          onClick={() => {
            gerarPDF();
          }}
        />
        <ButtonReportExcel
          onClick={() => {
            gerarCSV();
          }}
        />
        {csvData && (
          <CSVLink
            data={csvData}
            headers={[
              { label: 'Nro Solicitação', key: 'NRO_SOLICITACAO' },
              { label: 'Solução', key: 'SOLUCAO' },
              { label: 'Tipo Demanda', key: 'TIPO_SERVICO' },
              { label: 'Versão', key: 'VERSAO' },
              { label: 'Release Patch', key: 'RELEASE_PATCH' },
              { label: 'Data Previsão', key: 'DATA_PREVISAO' },
              { label: 'Data Conclusão', key: 'DATA_CONCLUSAO' },
              { label: 'Colaborador', key: 'NOME_COLABORADOR' },
              { label: 'Ano', key: 'ANO' },
            ]}
            filename="Quantidade de solicitações atrasadas.csv"
            onClick={() => {
              setCsvData();
            }}
          >
            Baixar CSV
          </CSVLink>
        )}
        <ChartTitle title={props.title} />
      </TitleWrapper>

      <Component>
        {!exibirAvisoNaoEncontrado && (
          <ReportDetail>
            <ReportDetailInfo>
              {etapaAtual !== 1 && (
                <ButtonGoBackReport
                  onClick={() => {
                    resolveRetrocederEtapa();
                  }}
                />
              )}

              <p style={{ marginLeft: '10px' }}>{getStringDetailInfo()}</p>
            </ReportDetailInfo>
            <ReportDetailTotal>
              {total && <p style={{ marginRight: '10px' }}>Total: {total}</p>}
            </ReportDetailTotal>
          </ReportDetail>
        )}
        <ContainerGrafico>
          {exibirAvisoNaoEncontrado && <ContentNotFound />}
          {resolveGraficoVisivel()}
        </ContainerGrafico>
      </Component>

      <ModalChart isOpen={modalIsOpen}>
        <ModalBlankZone
          onClick={e => {
            setIsOpen(false);
          }}
        />
        <ModalWindowZone
          style={{
            top: '100px',
            left: '10px',
          }}
        >
          <ModalTitle>
            Filtros - Solicitações fora do prazo de entrega
          </ModalTitle>
          <ModalInputContainer>
            <label>Ano</label>
            <select
              defaultValue={formValues.ano}
              onChange={handleChange}
              name="ano"
            >
              {anos &&
                anos.map(function (v) {
                  return (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  );
                })}
            </select>
          </ModalInputContainer>

          <ModalInputContainer>
            <label>Situação</label>
            <select
              defaultValue={formValues.emAberto}
              onChange={handleChange}
              name="emAberto"
            >
              <option value="" />
              <option value="S">Não concluídas</option>
            </select>
          </ModalInputContainer>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <ModalInputContainer>
              <label htmlFor="dataInicial">Data Inicial</label>
              <input
                type="date"
                name="dataInicial"
                defaultValue={formValues.dataInicial}
                onChange={handleChange}
              />
            </ModalInputContainer>
            <ModalInputContainer>
              <label htmlFor="dataFinal">Data Final</label>
              <input
                type="date"
                name="dataFinal"
                defaultValue={formValues.dataFinal}
                onChange={handleChange}
              />
            </ModalInputContainer>
          </div>
          <ModalInputContainer>
            <label>Solução</label>
            <select
              defaultValue={formValues.solucao}
              onChange={handleChange}
              name="solucao"
            >
              <option value="" />
              {solucoes &&
                solucoes.map(function (v) {
                  return (
                    <option key={v.solucao} value={v.solucao}>
                      {v.solucao}
                    </option>
                  );
                })}
            </select>
          </ModalInputContainer>
          {formValues.solucao && (
            <ModalInputContainer>
              <label>Versão</label>
              <select
                defaultValue={formValues.versao}
                onChange={handleChange}
                name="versao"
              >
                <option value="" />
                {versoes &&
                  versoes.map(function (v) {
                    if (isVersaoSolucao(formValues.solucao, v)) {
                      return (
                        <option key={v.id} value={v.id}>
                          {v.versao}
                        </option>
                      );
                    }
                  })}
              </select>
            </ModalInputContainer>
          )}
          <ModalInputContainer>
            <label>Colaborador</label>
            <select
              defaultValue={formValues.colaborador}
              onChange={handleChange}
              name="colaborador"
            >
              <option value="" />
              {colaboradores &&
                colaboradores.map(function (v) {
                  return (
                    <option key={v.codigo} value={v.codigo}>
                      {v.colaborador}
                    </option>
                  );
                })}
            </select>
          </ModalInputContainer>
          <ModalButtonContainer>
            <BaseButton type="button" model="close" onClick={closeModal} text />
            <BaseButton
              type="button"
              model="check"
              onClick={filtrarByModal}
              text
            />
          </ModalButtonContainer>
        </ModalWindowZone>
      </ModalChart>
    </Container>
  );
}

export default ChartPrazoEntrega;
