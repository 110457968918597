import { Defeito } from 'types';

interface IResponse {
  value: string;
  label: string;
}

function getDefaultGravityOption(problem: Defeito): IResponse | null {
  switch (problem.gravidade) {
    case 'A': {
      return {
        value: 'A',
        label: 'Alta',
      };
    }
    case 'M': {
      return {
        value: 'M',
        label: 'Média',
      };
    }
    case 'B': {
      return {
        value: 'B',
        label: 'Baixa',
      };
    }
    default: {
      return null;
    }
  }
}

export { getDefaultGravityOption };
