import styled, { css } from 'styled-components';

import { COLORS } from 'styles';

type ContainerProps = {
  isErrored: boolean;
  isFocused: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  border: 1px solid #595959;
  border-radius: 5px;
  padding: 10px 10px;
  line-height: 26px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: border 0.2s ease 0s;
  background: ${COLORS.BACKGROUND_LIGHT};

  & + div {
    margin-top: 14px;
  }

  ${props =>
    props.isFocused &&
    css`
      border-color: ${COLORS.LIGHTEN_15};
    `}

  ${props =>
    props.isErrored &&
    css`
      border-color: ${COLORS.ALERT};
    `}

  textarea {
    flex: 1;
    background: ${COLORS.BACKGROUND_LIGHT};
    border: 0;
    color: ${COLORS.FONT};

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #555;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #999;
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  svg {
    margin-right: 16px;
  }
`;
