import {
  FiCheck,
  FiX,
  FiArrowRight,
  FiEye,
  FiTool,
  FiTrash,
  FiPlus,
  FiCopy,
  FiEdit,
  FiSearch,
} from 'react-icons/fi';
import { CgDetailsMore } from 'react-icons/cg';

import { COLORS } from 'styles';

export type OptionsType =
  | 'check'
  | 'close'
  | 'upRight'
  | 'eye'
  | 'problem'
  | 'delete'
  | 'plus'
  | 'duplicate'
  | 'edit'
  | 'search'
  | 'details';

const options = {
  check: {
    Icon: FiCheck,
    label: 'Salvar',
    color: COLORS.SUCCESS,
  },
  close: {
    Icon: FiX,
    label: 'Fechar',
    color: COLORS.ALERT,
  },
  upRight: {
    Icon: FiArrowRight,
    label: 'Ir',
    color: COLORS.GREY,
  },
  eye: {
    Icon: FiEye,
    label: 'Visualizar',
    color: COLORS.GREY,
  },
  problem: {
    Icon: FiTool,
    label: 'Gestão de Defeitos',
    color: COLORS.BLUE,
  },
  delete: {
    Icon: FiTrash,
    label: 'Deletar',
    color: COLORS.ALERT,
  },
  plus: {
    Icon: FiPlus,
    label: 'Adicionar',
    color: COLORS.SUCCESS,
  },
  duplicate: {
    Icon: FiCopy,
    label: 'Duplicar',
    color: COLORS.YELLOW,
  },
  edit: {
    Icon: FiEdit,
    label: 'Editar',
    color: COLORS.YELLOW,
  },
  search: {
    Icon: FiSearch,
    label: 'Procurar',
    color: COLORS.FONT_LIGHT,
  },
  details: {
    Icon: CgDetailsMore,
    label: 'Mais detalhes',
    color: COLORS.FONT,
  },
};

export default options;
