import { FormHandles } from '@unform/core';
import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import {
  BaseButton,
  Textarea,
  Modal,
  Subtitle,
  SelectWithUnform,
  Line,
} from 'components';

import { api } from 'services';

import { useProblems, useSteps } from 'hooks';

import {
  getStepsOptions,
  getDefaultStepOption,
  getValidationErrors,
} from 'utils';

import { Buttons } from '../styles';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  versionId: number;
  stepId?: number;
};

type CreateProblemFormData = {
  descr_defeito: string;
  gravidade: 'A' | 'M' | 'B';
  id_ordem_cenario_teste: number;
  id_etapa_cenario_teste?: number;
};

export function CreateProblemModal({
  isOpen,
  toggleOpen,
  versionId,
  stepId,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);

  const { setProblems } = useProblems();

  const { steps } = useSteps();

  const handleSubmit = useCallback(
    async (data: CreateProblemFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_defeito: Yup.string().required(),
          gravidade: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post('/problem', {
          descr_defeito: data.descr_defeito,
          gravidade: data.gravidade,
          id_ordem_cenario_teste: versionId,
          id_etapa_cenario_teste: data.id_etapa_cenario_teste || null,
        });

        setProblems(state => [response.data, ...state]);

        toggleOpen();

        toast.success('Problema registrado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho!');

          return;
        }

        toast.error('Falha no registro do problema!');
      }
    },
    [toggleOpen, versionId, setProblems],
  );

  const stepsOptions = useMemo(() => getStepsOptions(steps), [steps]);

  const defaultStepValue = useMemo(() => getDefaultStepOption(steps, stepId), [
    stepId,
    steps,
  ]);

  return (
    <Modal isOpen={isOpen} title="Registrar problema" width={600}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Subtitle>* Descrição</Subtitle>
        <Textarea name="descr_defeito" placeholder="Descrição do problema" />

        <Subtitle>* Gravidade</Subtitle>
        <SelectWithUnform
          name="gravidade"
          options={[
            { label: 'Alta', value: 'A' },
            { label: 'Média', value: 'M' },
            { label: 'Baixa', value: 'B' },
          ]}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          isSearchable
          defaultValue={{
            label: 'Selecione a gravidade',
            value: null,
          }}
        />

        <Subtitle>Passo</Subtitle>
        <SelectWithUnform
          name="id_etapa_cenario_teste"
          options={stepsOptions}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          defaultValue={
            defaultStepValue || {
              label: 'Selecione o passo',
              value: null,
            }
          }
          isSearchable
          isClearable
        />

        <Line />
        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="plus" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
