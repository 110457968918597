import { Passo } from 'types';

interface IResponse {
  value: number;
  label: string;
}

function getStepsOptions(steps: Passo[]): IResponse[] {
  return steps.map(step => ({
    label: `${step.ordem_etapa}: ${step.descr_etapa}`,
    value: step.id,
  }));
}

export { getStepsOptions };
