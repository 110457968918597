import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import {
  BaseButton,
  Modal,
  Select,
  Step,
  Description,
  Subtitle,
  Title,
  Line,
} from 'components';

import { api } from 'services';

import { useSteps, useVersions } from 'hooks';

import { getServiceOrderOptions, getStatusOption } from 'utils';

import { Cenario, OrdemServico } from 'types';

import { Buttons } from 'components/Modal/styles';

import { Steps } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  scenario: Cenario;
}

export function ViewScenarioModal({
  isOpen,
  toggleOpen,
  scenario,
}: ModalProps) {
  const { setSteps, steps } = useSteps();
  const { setVersions, versions } = useVersions();
  const [
    selectedServiceOrder,
    setSelectedServiceOrder,
  ] = useState<OrdemServico>({} as OrdemServico);
  const [versionStatus, setVersionStatus] = useState('undefined');

  useEffect(() => {
    if (!scenario.id || !isOpen) {
      return;
    }

    api.get(`/versions/${scenario.id}`).then(response => {
      setVersions(response.data);

      setSelectedServiceOrder(
        response.data[response.data.length - 1] || ({} as OrdemServico),
      );
    });
  }, [scenario.id, setVersions, isOpen]);

  useEffect(() => {
    if (!selectedServiceOrder.id || !isOpen) {
      setSteps([]);
      return;
    }

    api.get(`steps/${selectedServiceOrder.id}`).then(response => {
      setSteps(response.data);
    });
  }, [selectedServiceOrder, setSteps, isOpen]);

  const serviceOrderOptions = useMemo(() => {
    return getServiceOrderOptions(versions);
  }, [versions]);

  const handleServiceOrderChange = useCallback(
    e => {
      setSelectedServiceOrder(
        versions.find(version => version.id === e.value) ||
          ({} as OrdemServico),
      );
    },
    [versions],
  );

  useEffect(() => {
    const serviceOrder = versions.find(
      version => version.id === selectedServiceOrder?.id,
    );
    const status = getStatusOption(serviceOrder);
    setVersionStatus(status);
  }, [selectedServiceOrder, versions]);

  return (
    <Modal isOpen={isOpen} width={800}>
      <Title>Visualizar cenário</Title>

      <Description>Nome</Description>

      <p>{scenario.descr_cenario}</p>

      <Description>Condição</Description>

      <p style={{ whiteSpace: 'pre-wrap' }}>{scenario.descr_condicao}</p>

      <Description>Resultado esperado</Description>

      <p style={{ whiteSpace: 'pre-wrap' }}>
        {scenario.descr_resultado_esperado}
      </p>

      <Line />

      <Subtitle>Ordem de serviço</Subtitle>

      <Select
        options={serviceOrderOptions}
        isSearchable
        onChange={handleServiceOrderChange}
        value={{
          label:
            selectedServiceOrder.nro_ordem ||
            'Selecione alguma ordem de serviço',
          value: selectedServiceOrder.id || null,
        }}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />

      {selectedServiceOrder.id && (
        <>
          <Description>Status</Description>
          <div style={{ display: 'flex' }}>
            {selectedServiceOrder.status === 'A' && (
              <FiCheck color="#55a630" size={20} />
            )}
            {selectedServiceOrder.status === 'R' && (
              <FiX color="#e63946" size={20} />
            )}
            {selectedServiceOrder.status === 'P' && (
              <IoHelp color="#f4a261" size={20} />
            )}

            <span>{versionStatus}</span>
          </div>

          <Description>
            Requer atualização nos testes automatizados?
          </Description>
          <div style={{ display: 'flex' }}>
            {selectedServiceOrder.exige_atualizacao_teste === 'S' && <p>Sim</p>}
            {selectedServiceOrder.exige_atualizacao_teste === 'N' && <p>Não</p>}
          </div>
        </>
      )}

      <Line />

      <Steps>
        <Subtitle>Etapas</Subtitle>

        {steps?.map((step, index) => (
          <Step
            key={step.id}
            step={step}
            index={index}
            viewMode
            isEditing={false}
          />
        ))}
      </Steps>

      <Line />

      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />
        {selectedServiceOrder && (
          <Link to={`/problems/${selectedServiceOrder.id}`}>
            <BaseButton model="problem" text />
          </Link>
        )}
      </Buttons>
    </Modal>
  );
}
