import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import {
  BaseButton,
  Input,
  Modal,
  Subtitle,
  ModalTitle,
  Line,
} from 'components';

import { api } from 'services';

import { Projeto } from 'types';

import { getValidationErrors } from 'utils';

import { Buttons } from 'components/Modal/styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  project: Projeto;
  setProjects: React.Dispatch<React.SetStateAction<Projeto[]>>;
  setSelectedProject: React.Dispatch<React.SetStateAction<Projeto | undefined>>;
}

interface EditProjectFormData {
  descr_projeto: string;
}

export function EditProjectModal({
  isOpen,
  toggleOpen,
  project,
  setProjects,
  setSelectedProject,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: EditProjectFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_projeto: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/project/${project.id}`, data);

        setSelectedProject(response.data);

        setProjects(state =>
          state.map(mappedProject =>
            mappedProject.id === project.id ? response.data : mappedProject,
          ),
        );

        toast.success('Projeto atualizado com sucesso!');

        toggleOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho!');

          return;
        }

        toast.error('Falha ao atualizar projeto!');
      }
    },
    [setProjects, toggleOpen, project.id, setSelectedProject],
  );

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle>Editar projeto</ModalTitle>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={project}>
        <Subtitle>* Nome do Projeto</Subtitle>
        <Input name="descr_projeto" placeholder="Nome do projeto" />

        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="check" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
