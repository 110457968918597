/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { api } from 'services';
import { getMes, timeConvert } from '../utils/metrics.jsx';

class ReportHorasServico {
  constructor(dadosGeral, filtros) {
    this.doc = new jsPDF();
    this.filtros = filtros;
    this.dadosGeral = dadosGeral;
    this.posicaoY = 20;
  }

  async gerarDocumento() {
    const me = this;

    const registros = await me.buscaDados();

    const rowsGeral = [];

    me.doc.setFontSize(12);

    me.doc.text('Quantidade de horas por tipo de demanda', 14, me.posicaoY);

    me.addNovaLinhaNormal();

    me.doc.setFontSize(10);

    me.addLinhaPeriodo();
    me.addLinhaSolucao();
    me.addLinhaColaborador();

    let total = 0;
    let totalBug = 0;
    let totalCustomizacao = 0;
    let totalMelhoria = 0;
    let totalGeral = 0;
    this.dadosGeral.forEach(registro => {
      const valoresRegistro = [
        getMes(registro.mes),
        registro.Bug,
        registro.Melhoria,
        registro['Customização'],
        0,
      ];

      const bug = registro.Bug || 0;
      const melhoria = registro.Melhoria || 0;
      const customizacao = registro['Customização'] || 0;

      total += bug + melhoria + customizacao;
      totalBug += bug;
      totalMelhoria += melhoria;
      totalCustomizacao += customizacao;
      totalGeral = bug + melhoria + customizacao;
      valoresRegistro[1] = timeConvert(valoresRegistro[1]);
      valoresRegistro[2] = timeConvert(valoresRegistro[2]);
      valoresRegistro[3] = timeConvert(valoresRegistro[3]);
      valoresRegistro[4] = timeConvert(totalGeral);

      if (me.deveExibirMes(registro.mes)) {
        rowsGeral.push(valoresRegistro);
      }
    });

    if (rowsGeral.length > 1) {
      rowsGeral.unshift([
        'Total',
        timeConvert(totalBug),
        timeConvert(totalMelhoria),
        timeConvert(totalCustomizacao),
        timeConvert(total),
      ]);
    }

    me.doc.autoTable({
      startY: me.posicaoY,
      head: [['Mês', 'Bug', 'Melhoria', 'Customização', 'Total']],
      body: rowsGeral.reverse(),
    });

    me.posicaoY = me.doc.lastAutoTable.finalY + 5;

    me.posicaoY += 5;

    let arrColab = [];
    let arrSolucao = [];
    let arrMes = [];

    if (registros) {
      registros.forEach(rec => {
        arrColab.push(rec.NOME_COLABORADOR);
        arrMes.push(rec.MES);
        arrSolucao.push(rec.SOLUCAO);
      });

      arrColab = [...new Set(arrColab)];
      arrSolucao = [...new Set(arrSolucao)];
      arrMes = [...new Set(arrMes)];
    }

    if (!me.filtros.solucao) {
      me.addTabelaSolucao(arrSolucao, arrMes, registros);
      me.posicaoY = me.doc.lastAutoTable.finalY + 5;
      me.posicaoY += 5;
    }
    if (!me.filtros.colaborador) {
      me.addTabelaColaborador(arrColab, arrMes, registros);
    }

    me.doc.save('Quantidade de horas por tipo de demanda.pdf');
  }

  deveExibirMes(mes) {
    const me = this;

    const { dataInicial } = me.filtros;
    const { dataFinal } = me.filtros;

    let continuar = true;

    if (dataInicial && dataFinal) {
      const i = Number(dataInicial.substr(5, 2));
      const f = Number(dataFinal.substr(5, 2));
      const m = Number(mes.substr(0, 2));

      if (m < i || m > f) {
        continuar = false;
      }
    }
    if (dataInicial && !dataFinal) {
      const d = Number(dataInicial.substr(5, 2));
      const m = Number(mes.substr(0, 2));
      if (m < d) {
        continuar = false;
      }
    }
    if (!dataInicial && dataFinal) {
      const d = Number(dataInicial.substr(5, 2));
      const m = Number(mes.substr(0, 2));
      if (m > d) {
        continuar = false;
      }
    }

    return continuar;
  }

  addTabelaSolucao(arrSolucao, arrMes, registros) {
    const me = this;
    me.doc.text('Horas por Solução', 14, me.posicaoY);
    me.posicaoY += 5;

    arrSolucao.forEach((solucao, idx) => {
      const a2 = [];
      arrMes.forEach(mes => {
        if (me.deveExibirMes(mes)) {
          a2.push({
            MES: mes,
            BUG: 0,
            MELHORIA: 0,
            CUSTOMIZACAO: 0,
          });
        }
      });

      a2.forEach(rec => {
        const bug = registros.filter(
          el =>
            rec.MES === el.MES &&
            solucao === el.SOLUCAO &&
            el.SERVICO === 'Bug',
        );
        const customizacao = registros.filter(
          el =>
            rec.MES === el.MES &&
            solucao === el.SOLUCAO &&
            el.SERVICO === 'Customização',
        );
        const melhoria = registros.filter(
          el =>
            rec.MES === el.MES &&
            solucao === el.SOLUCAO &&
            el.SERVICO === 'Melhoria',
        );

        rec.BUG = bug ? me.somaHoras(bug) : 0;
        rec.CUSTOMIZACAO = customizacao ? me.somaHoras(customizacao) : 0;
        rec.MELHORIA = melhoria ? me.somaHoras(melhoria) : 0;
      });

      const aux = [];
      let totalBug = 0;
      let totalCustomizacao = 0;
      let totalMelhoria = 0;
      let totalGeral = 0;

      a2.forEach(reg => {
        totalBug += reg.BUG;
        totalMelhoria += reg.MELHORIA;
        totalCustomizacao += reg.CUSTOMIZACAO;
        totalGeral += reg.BUG + reg.MELHORIA + reg.CUSTOMIZACAO;

        aux.push([
          getMes(reg.MES),
          timeConvert(reg.BUG),
          timeConvert(reg.MELHORIA),
          timeConvert(reg.CUSTOMIZACAO),
          timeConvert(reg.BUG + reg.MELHORIA + reg.CUSTOMIZACAO),
        ]);
      });

      if (aux.length > 1) {
        aux.push([
          'Total',
          timeConvert(totalBug),
          timeConvert(totalMelhoria),
          timeConvert(totalCustomizacao),
          timeConvert(totalGeral),
        ]);
      }

      if (idx > 0) {
        me.posicaoY = me.doc.lastAutoTable.finalY + 5;
        me.posicaoY += 5;
      }
      me.doc.autoTable({
        startY: me.posicaoY,
        head: [[solucao, 'Bug', 'Melhoria', 'Customização', 'Total']],
        body: aux,
      });
    });
  }

  addTabelaColaborador(arrColaborador, arrMes, registros) {
    const me = this;
    me.doc.text('Horas por Colaborador', 14, me.posicaoY);
    me.posicaoY += 5;
    arrColaborador.forEach((colaborador, idx) => {
      const a2 = [];
      arrMes.forEach(mes => {
        a2.push({
          NOME_COLABORADOR: colaborador,
          MES: mes,
          BUG: 0,
          MELHORIA: 0,
          CUSTOMIZACAO: 0,
        });
      });

      a2.forEach(rec => {
        const bug = registros.find(
          el =>
            rec.MES === el.MES &&
            rec.NOME_COLABORADOR === el.NOME_COLABORADOR &&
            el.SERVICO === 'Bug',
        );
        const customizacao = registros.find(
          el =>
            rec.MES === el.MES &&
            rec.NOME_COLABORADOR === el.NOME_COLABORADOR &&
            el.SERVICO === 'Customização',
        );
        const melhoria = registros.find(
          el =>
            rec.MES === el.MES &&
            rec.NOME_COLABORADOR === el.NOME_COLABORADOR &&
            el.SERVICO === 'Melhoria',
        );

        rec.BUG = bug ? bug.HORAS_DESENV : 0;
        rec.CUSTOMIZACAO = customizacao ? customizacao.HORAS_DESENV : 0;
        rec.MELHORIA = melhoria ? melhoria.HORAS_DESENV : 0;
      });

      const aux = [];
      let totalBug = 0;
      let totalCustomizacao = 0;
      let totalMelhoria = 0;
      let totalGeral = 0;

      a2.forEach(reg => {
        totalBug += reg.BUG;
        totalMelhoria += reg.MELHORIA;
        totalCustomizacao += reg.CUSTOMIZACAO;
        totalGeral += reg.BUG + reg.MELHORIA + reg.CUSTOMIZACAO;

        aux.push([
          getMes(reg.MES),
          timeConvert(reg.BUG),
          timeConvert(reg.MELHORIA),
          timeConvert(reg.CUSTOMIZACAO),
          timeConvert(reg.BUG + reg.MELHORIA + reg.CUSTOMIZACAO),
        ]);
      });

      if (aux.length > 1) {
        aux.push([
          'Total',
          timeConvert(totalBug),
          timeConvert(totalMelhoria),
          timeConvert(totalCustomizacao),
          timeConvert(totalGeral),
        ]);
      }
      if (idx > 0) {
        me.posicaoY = me.doc.lastAutoTable.finalY + 5;
        me.posicaoY += 5;
      }

      me.doc.autoTable({
        startY: me.posicaoY,
        head: [[colaborador, 'Bug', 'Melhoria', 'Customização', 'Total']],
        body: aux,
      });
    });
  }

  somaHoras(array) {
    let total = 0;
    array.forEach(val => {
      total += val.HORAS_DESENV;
    });
    return total;
  }

  padHours(n) {
    return n.toString().padStart(2, '0');
  }

  padMinutes(n) {
    return n.toString().padEnd(2, '0');
  }

  async buscaDados() {
    const me = this;
    const response = await api.get('horasServico/pdf', {
      params: me.filtros,
    });

    return response.data;
  }

  addNovaLinhaNormal() {
    this.posicaoY += 5;
  }

  strDate(date) {
    const dia = date.substring(8, 10);
    const mes = date.substring(5, 7);
    const ano = date.substring(0, 4);
    return `${dia}/${mes}/${ano}`;
  }

  addLinhaPeriodo = () => {
    const me = this;
    const { filtros } = me;
    const ano = `Ano: ${filtros.ano}`;
    let dataInicial = '';
    let dataFinal = '';

    if (filtros.dataInicial) {
      dataInicial = `    Data Inicial: ${me.strDate(filtros.dataInicial)}`;
    }

    if (filtros.dataFinal) {
      dataFinal = `    Data Final: ${me.strDate(filtros.dataFinal)}`;
    }

    me.doc.text(`${ano}${dataInicial}${dataFinal}`, 14, me.posicaoY);
    me.addNovaLinhaNormal();
  };

  addLinhaSolucao = () => {
    const me = this;
    const { filtros } = me;
    let solucao = '';
    let versao = '';

    if (filtros.solucao) {
      solucao = `Solução: ${filtros.solucao}`;
    }

    if (filtros.versao) {
      const gap = solucao ? '    ' : '';
      versao = `${gap}Versão: ${filtros.descritivoVersao}`;
    }

    if (solucao || versao) {
      me.doc.text(`${solucao}${versao}`, 14, me.posicaoY);
      me.addNovaLinhaNormal();
    }
  };

  addLinhaColaborador = () => {
    const me = this;
    const { filtros } = me;
    let colaborador = '';
    if (filtros.colaborador) {
      colaborador = `Colaborador: ${filtros.colaborador}`;
      me.doc.text(
        `${colaborador} - ${filtros.nomeColaborador}`,
        14,
        me.posicaoY,
      );
      me.addNovaLinhaNormal();
    }
  };
}

export default ReportHorasServico;
