import React, { useCallback } from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { Modal, BaseButton, Line } from 'components';

import { api } from 'services';

import { Defeito } from 'types';

import { Buttons } from 'components/Modal/styles';

import './styles.scss';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  problemId: number;
  setProblems: React.Dispatch<React.SetStateAction<Defeito[]>>;
  setSelectedProblem: React.Dispatch<React.SetStateAction<Defeito | undefined>>;
};

export function DeleteProblemModal({
  isOpen,
  toggleOpen,
  problemId,
  setProblems,
  setSelectedProblem,
}: ModalProps) {
  const deleteProblem = useCallback(async () => {
    try {
      await api.delete(`/problem/${problemId}`);

      setProblems(state =>
        produce(state, drafts =>
          drafts.filter(draft => draft.id !== problemId),
        ),
      );

      setSelectedProblem(undefined);

      toggleOpen();

      toast.success('Problema deletado com sucesso!');
    } catch {
      toast.error('Falha ao deletar registro!');
    }
  }, [problemId, setProblems, setSelectedProblem, toggleOpen]);

  return (
    <Modal isOpen={isOpen} title="Atenção">
      <div className="delete-problem">
        <p>Tem certeza que deseja excluir este registro?</p>
      </div>

      <Line />

      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />
        <BaseButton model="check" onClick={deleteProblem} text />
      </Buttons>
    </Modal>
  );
}
