/* eslint-disable import/no-duplicates */

import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { BaseButton, Higher, Page } from 'components';

import { api } from 'services';

import { OrdemServico } from 'types';

import { useAuth, useVersions } from 'hooks';

import { getDefaultStatusOption } from 'utils';

import { ViewServiceOrderModal } from './ViewServiceOrderModal';
import { EditServiceOrderModalPrimary } from './EditServiceOrderModalPrimary';

export function Versions() {
  const [loadingVersions, setLoadingVersions] = useState(true);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [selectedVersion, setSelectedVersion] = useState<
    OrdemServico | undefined
  >(undefined);

  const { versions, setVersions } = useVersions();
  const { signOut } = useAuth();

  const toggleViewModalOpen = useCallback(() => {
    setIsViewModalOpen(state => !state);
  }, []);

  const toggleEditModalOpen = useCallback(() => {
    setIsEditModalOpen(state => !state);
  }, []);

  function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const name = query.get('name');

  useEffect(() => {
    document.title = 'Ordens de Serviços - Test Soft';

    if (!name) {
      return;
    }

    api
      .get(`/versions/search?name=${name}`)
      .then(response => {
        setVersions(response.data);
      })
      .catch(err => {
        if (err.response?.data.newTokenRequired) {
          toast.error('Sessão expirada!');
          signOut();
        }
      })
      .finally(() => setLoadingVersions(false));
  }, [name, setVersions, signOut]);

  return (
    <Page loading={loadingVersions}>
      <Higher title="Ordens de serviços" />

      {selectedVersion && (
        <>
          <ViewServiceOrderModal
            isOpen={isViewModalOpen}
            toggleOpen={toggleViewModalOpen}
            ordemServico={selectedVersion}
          />

          <EditServiceOrderModalPrimary
            isOpen={isEditModalOpen}
            toggleOpen={toggleEditModalOpen}
            version_id={selectedVersion.id}
          />
        </>
      )}

      <div className="tools-buttons">
        <BaseButton
          model="eye"
          text
          onClick={toggleViewModalOpen}
          disabled={!selectedVersion}
        />

        <BaseButton
          model="edit"
          text
          onClick={toggleEditModalOpen}
          disabled={!selectedVersion}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Número ordem serviço</th>
              <th>Programa</th>
              <th>Cenário</th>
              <th>Status</th>
              <th>Atualizado há</th>
            </tr>
          </thead>

          <tbody>
            {versions.map(version => (
              <tr
                key={version.id}
                onClick={() => {
                  if (selectedVersion?.id === version.id) {
                    setSelectedVersion(undefined);
                  } else {
                    setSelectedVersion(version);
                  }
                }}
                onDoubleClick={() => {
                  setSelectedVersion(version);

                  toggleViewModalOpen();
                }}
                className={
                  version.id === selectedVersion?.id
                    ? 'selected-row'
                    : 'not-selected-row'
                }
              >
                <td>{version.nro_ordem}</td>
                <td>
                  {
                    /* @ts-ignore */
                    version.cenario_teste?.programa_cenario_teste
                      ?.cod_programa || 'undefined'
                  }
                </td>
                <td>
                  {
                    /* @ts-ignore */
                    version.cenario_teste?.descr_cenario || 'undefined'
                  }
                </td>
                <td
                  style={{
                    color:
                      (version.status === 'A' && 'var(--success)') ||
                      (version.status === 'R' && 'var(--alert)') ||
                      'var(--accent)',
                  }}
                >
                  {getDefaultStatusOption(version)?.label || 'Não definido'}
                </td>
                <td>
                  {version.data_atualizacao &&
                    formatDistance(
                      new Date(),
                      new Date(version.data_atualizacao),
                      {
                        locale: ptBR,
                      },
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {versions.length <= 0 && (
          <p style={{ marginTop: 30 }}>
            Não foi encontrada nenhuma ordem de serviço com o número {name}!
          </p>
        )}
      </div>
    </Page>
  );
}
