import { useCallback, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';

import { LargeButton, Input, Page } from 'components';

import { api } from 'services';

import { useAuth } from 'hooks';

import { getValidationErrors } from 'utils';

import { COLORS } from 'styles';

import avatarImg from '../../assets/default.png';

import { Form, AvatarInput } from './styles';

interface ProfileFormData {
  senha_antiga: string;
  senha: string;
  confirmacao_senha: string;
}

export function Profile() {
  const formRef = useRef<FormHandles>(null);
  const { signOut, usuario, updateUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    document.title = 'Perfil - Test Soft';

    api
      .get('profile')
      .then(response => {
        updateUser(response.data);
      })
      .catch(err => {
        if (err.response?.data.newTokenRequired) {
          toast.error('Sessão expirada!');
          signOut();
        }
      });
  }, [signOut, updateUser]);

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          senha_antiga: Yup.string().required(),
          senha: Yup.string().when('senha_antiga', {
            is: val => !!val.length,
            then: Yup.string().required('Required field'),
            otherwise: Yup.string(),
          }),
          confirmacao_senha: Yup.string()
            .when('senha_antiga', {
              is: val => !!val.length,
              then: Yup.string().required('Required field'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('senha')], 'Incorrect confirmation'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put('/profile', data);

        updateUser(response.data);

        history.push('/projects');

        toast.success('Seu perfil foi atualizado!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error('Erro ao atualizar perfil!');
      }
    },
    [history, updateUser],
  );

  return (
    <Page>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={usuario}>
        <AvatarInput>
          <img src={avatarImg} alt={usuario.nome_usuario} />
        </AvatarInput>

        <strong>Nome completo</strong>

        <span>{usuario.nome_usuario}</span>

        <strong>E-mail</strong>

        <span>{usuario.email}</span>

        <Input name="senha_antiga" type="password" placeholder="Senha atual" />

        <Input name="senha" type="password" placeholder="Nova senha" />

        <Input
          name="confirmacao_senha"
          type="password"
          placeholder="Confirmação de senha"
        />

        <LargeButton type="submit" color={COLORS.ACCENT} title="Salvar" />

        <LargeButton
          type="button"
          onClick={signOut}
          color={COLORS.ALERT}
          title="Sair"
        />
      </Form>
    </Page>
  );
}
