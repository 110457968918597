import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

export const Title = styled.h1`
  display: flex;
  padding-bottom: 10px;
  color: #fff;
  font-size: 24px;
`;

export const Form = styled(Unform)`
  color: #fff;
  border-radius: 5px;
  margin-top: 10px;

  button {
    margin: 10px 0 0 auto;
  }

  > header {
    display: flex;
    justify-content: space-between;

    div {
      display: flex;

      button + button {
        margin-left: 15px;
      }
    }
  }
`;

export const SubtitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  color: #fff;

  form + & {
    margin-top: 10px;
  }

  header {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      margin-left: auto;
      margin-bottom: 10px;

      button {
        svg {
          width: 23px;
        }
      }

      button + button {
        margin-left: 15px;
      }
    }
  }
`;
