import { useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { useAuth } from 'hooks';

import { Input, BaseButton } from 'components';

import { getValidationErrors } from 'utils';

import logo from '../../assets/logo.svg';

import avatar from '../../assets/default.png';

import './styles.scss';

export function Header() {
  const { usuario } = useAuth();
  const ref = useRef<FormHandles>(null);

  const history = useHistory();

  const handleSubmit = useCallback(
    async data => {
      try {
        ref.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Campo de nome obrigatório!')
            .min(4, 'Mínimo 4 caracteres!'),
        });

        await schema.validate(data, { abortEarly: false });

        history.push({ pathname: '/versions', search: `?name=${data.name}` });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          ref.current?.setErrors(errors);

          err.errors.forEach(erro => {
            toast.error(erro);
          });
        }
      }
    },
    [history],
  );

  return (
    <header className="header-container">
      <div className="header-content">
        <div className="header-left-content">
          <Link to="/">
            <img src={logo} alt="Grupo Insoft4" />
          </Link>
        </div>

        {usuario && (
          <div className="header-right-content">
            <Link to="/metricas">
              <button type="button" className="button-metrics">
                Métricas
              </button>
            </Link>
            <Form ref={ref} onSubmit={handleSubmit}>
              <Input name="name" placeholder="Número da OS" type="number" />

              <BaseButton model="search" type="submit" />
            </Form>
            <div className="header-profile">
              <Link to="/profile">
                <strong>{usuario.nome_usuario}</strong>
                <img src={avatar} alt="Avatar" />
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
