import React, { createContext, useState, useContext } from 'react';

import { OrdemServico } from 'types';

interface VersionsContextData {
  versions: OrdemServico[];
  setVersions: React.Dispatch<React.SetStateAction<OrdemServico[]>>;
}

const VersionsContext = createContext<VersionsContextData>(
  {} as VersionsContextData,
);

const VersionProvider: React.FC = ({ children }) => {
  const [versions, setVersions] = useState<OrdemServico[]>([]);

  return (
    <VersionsContext.Provider
      value={{
        versions,
        setVersions,
      }}
    >
      {children}
    </VersionsContext.Provider>
  );
};

function useVersions(): VersionsContextData {
  const context = useContext(VersionsContext);

  return context;
}

export { VersionProvider, useVersions };
