import { useCallback } from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { api } from 'services';

import { Modal, BaseButton, Line } from 'components';

import { useScenarios } from 'hooks';

import { Buttons } from 'components/Modal/styles';

import './styles.scss';
import { Cenario } from 'types';
import axios from 'axios';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  scenarioId: number;
  setSelectedScenario: React.Dispatch<
    React.SetStateAction<Cenario | undefined>
  >;
};

export function DeleteScenarioModal({
  isOpen,
  toggleOpen,
  scenarioId,
  setSelectedScenario,
}: ModalProps) {
  const { setScenarios, scenarios } = useScenarios();

  const deleteScenario = useCallback(async () => {
    try {
      await api.delete(`/scenarios/${scenarioId}`);

      setScenarios(
        produce(scenarios, drafts =>
          drafts.filter(draft => draft.id !== scenarioId),
        ),
      );

      setSelectedScenario(undefined);

      toggleOpen();
      toast.success('Cenário deletado com sucesso!');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error(err.response?.data.message ?? 'Falha ao deletar cenário!');
      }
    }
  }, [scenarioId, setScenarios, scenarios, setSelectedScenario, toggleOpen]);

  return (
    <Modal isOpen={isOpen} title="Atenção">
      <div className="delete-scenario">
        <p>Tem certeza que deseja excluir este registro?</p>
      </div>
      <Line />

      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />
        <BaseButton model="check" onClick={deleteScenario} text />
      </Buttons>
    </Modal>
  );
}
