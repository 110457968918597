import styled from 'styled-components';

export const ModalChart = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const ModalBlankZone = styled.div`
  background: rgba(18, 18, 20, 0.6);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
`;
export const ModalWindowZone = styled.div`
  border-radius: 5px;
  width: 400px;
  background: rgb(36, 31, 42);
  color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 102;
`;

export const ModalTitle = styled.p`
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
  color: white;
`;

export const ModalInputContainer = styled.div`
  display: flex;
  margin: 10px;
  flex-direction: column;

  select,
  input {
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    padding: 5px;
    margin-top: 3px;
    font-size: 14px;
    background: rgb(36, 31, 42);
    color: white;
  }

  option {
    padding: 5px;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;

  span {
    color: white;
  }
  span:hover {
    cursor: pointer;
    color: #fa8828;
  }
  button {
    margin-left: 10px;
  }
`;
