import { ReactNode } from 'react';

import './styles.scss';

type NavigationTreeProps = {
  children: ReactNode;
};

export function NavigationTree({ children }: NavigationTreeProps) {
  return <div className="navigation-tree">{children}</div>;
}
