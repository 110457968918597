/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { api } from 'services';

class ReportSolicitacaoAtrasada {
  constructor(filtros) {
    this.doc = new jsPDF();
    this.filtros = filtros;
    this.posicaoY = 20;
  }

  async gerarDocumento() {
    const me = this;

    const registros = await me.buscaDados();

    const rowsSolucao = [];
    const rowsVersao = [];
    const rowsColaborador = [];

    me.doc.setFontSize(12);

    me.doc.text('Solicitações fora do prazo de entrega', 14, me.posicaoY);

    me.addNovaLinhaNormal();

    me.doc.setFontSize(10);

    me.addLinhaPeriodo();
    me.addLinhaSolucao();
    me.addLinhaColaborador();

    let total = 0;
    let totalBugs = 0;
    let totalCustomizacoes = 0;
    let totalMelhorias = 0;
    registros.solucoes.forEach(registro => {
      rowsSolucao.push([
        registro.solucao,
        registro.bugs,
        registro.melhorias,
        registro.customizacoes,
        registro.totalSolicitacoes,
      ]);
      totalBugs += registro.bugs;
      totalMelhorias += registro.melhorias;
      totalCustomizacoes += registro.customizacoes;
      total += registro.totalSolicitacoes;
    });

    if (rowsSolucao.length > 1) {
      rowsSolucao.push([
        'Total',
        totalBugs,
        totalMelhorias,
        totalCustomizacoes,
        total,
      ]);
    }

    me.doc.autoTable({
      startY: me.posicaoY,
      head: [['Solução', 'Bug', 'Melhoria', 'Customização', 'Total']],
      body: rowsSolucao,
    });

    me.posicaoY = me.doc.lastAutoTable.finalY + 5;

    me.posicaoY += 5;

    let totalVersao = 0;
    let totalBugsVersao = 0;
    let totalCustomizacoesVersao = 0;
    let totalMelhoriasVersao = 0;

    registros.versoes.forEach(registro => {
      rowsVersao.push([
        registro.versao,
        registro.bugs,
        registro.melhorias,
        registro.customizacoes,
        registro.totalSolicitacoes,
      ]);
      totalBugsVersao += registro.bugs;
      totalMelhoriasVersao += registro.melhorias;
      totalCustomizacoesVersao += registro.customizacoes;
      totalVersao += registro.totalSolicitacoes;
    });

    if (rowsVersao.length > 1) {
      rowsVersao.push([
        'Total',
        totalBugsVersao,
        totalMelhoriasVersao,
        totalCustomizacoesVersao,
        totalVersao,
      ]);
    }

    if (!me.filtros.versao) {
      me.doc.autoTable({
        startY: me.posicaoY + 5,
        head: [['Versão', 'Bug', 'Melhoria', 'Customização', 'Total']],
        body: rowsVersao,
      });
    }

    let totalColaborador = 0;
    let totalBugsColaborador = 0;
    let totalCustomizacoesColaborador = 0;
    let totalMelhoriasColaborador = 0;

    registros.colaboradores.forEach(registro => {
      rowsColaborador.push([
        registro.colaborador,
        registro.bugs,
        registro.melhorias,
        registro.customizacoes,
        registro.totalSolicitacoes,
      ]);
      totalBugsColaborador += registro.bugs;
      totalCustomizacoesColaborador += registro.melhorias;
      totalMelhoriasColaborador += registro.customizacoes;
      totalColaborador += registro.totalSolicitacoes;
    });

    if (rowsColaborador.length > 1) {
      rowsColaborador.push([
        'Total',
        totalBugsColaborador,
        totalCustomizacoesColaborador,
        totalMelhoriasColaborador,
        totalColaborador,
      ]);
    }

    me.posicaoY = me.doc.lastAutoTable.finalY + 5;

    if (!me.filtros.colaborador) {
      me.doc.autoTable({
        startY: me.posicaoY + 5,
        head: [['Colaborador', 'Bug', 'Melhoria', 'Customização', 'Total']],
        body: rowsColaborador,
      });
    }
    me.posicaoY = me.doc.lastAutoTable.finalY + 5;

    me.doc.save('Solicitações fora do prazo de entrega.pdf');
  }

  async buscaDados() {
    const me = this;
    const response = await api.get('solicitacoesAtrasadas/pdf', {
      params: me.filtros,
    });
    return response.data;
  }

  addNovaLinhaNormal() {
    this.posicaoY += 5;
  }

  strDate(date) {
    const dia = date.substring(8, 10);
    const mes = date.substring(5, 7);
    const ano = date.substring(0, 4);
    return `${dia}/${mes}/${ano}`;
  }

  addLinhaPeriodo = () => {
    const me = this;
    const { filtros } = me;
    const ano = `Ano: ${filtros.ano}`;
    let dataInicial = '';
    let dataFinal = '';
    let emAberto = '';

    if (filtros.dataInicial) {
      dataInicial = `    Data Inicial: ${me.strDate(filtros.dataInicial)}`;
    }

    if (filtros.dataFinal) {
      dataFinal = `    Data Final: ${me.strDate(filtros.dataFinal)}`;
    }

    if (filtros.emAberto === 'S') {
      emAberto = `    Não concluídas`;
    }

    me.doc.text(`${ano}${dataInicial}${dataFinal}${emAberto}`, 14, me.posicaoY);
    me.addNovaLinhaNormal();
  };

  addLinhaSolucao = () => {
    const me = this;
    const { filtros } = me;
    let solucao = '';
    let versao = '';

    if (filtros.solucao) {
      solucao = `Solução: ${filtros.solucao}`;
    }

    if (filtros.versao) {
      const gap = solucao ? '    ' : '';
      versao = `${gap}Versão: ${filtros.descritivoVersao}`;
    }

    if (solucao || versao) {
      me.doc.text(`${solucao}${versao}`, 14, me.posicaoY);
      me.addNovaLinhaNormal();
    }
  };

  addLinhaColaborador = () => {
    const me = this;
    const { filtros } = me;
    let colaborador = '';
    if (filtros.colaborador) {
      colaborador = `Colaborador: ${filtros.colaborador}`;
      me.doc.text(
        `${colaborador} - ${filtros.nomeColaborador}`,
        14,
        me.posicaoY,
      );
      me.addNovaLinhaNormal();
    }
  };
}

export default ReportSolicitacaoAtrasada;
