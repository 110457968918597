import { FormHandles } from '@unform/core';
import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import produce from 'immer';

import {
  BaseButton,
  Input,
  Modal,
  SelectWithUnform as Select,
  Line,
  Subtitle,
} from 'components';

import { api } from 'services';

import { useVersions } from 'hooks';

import { OrdemServico } from 'types';

import {
  getValidationErrors,
  getDefaultStatusOption,
  getDefaultMaintenanceOption,
} from 'utils';

import { Buttons } from '../styles';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  version: OrdemServico;
  setVersion?: React.Dispatch<React.SetStateAction<OrdemServico | undefined>>;
  setSelectedVersion: React.Dispatch<
    React.SetStateAction<OrdemServico | undefined>
  >;
};

type EditServiceOrderFormData = {
  nro_ordem: string;
  status: 'N' | 'R' | 'A';
  exige_atualizacao_teste: 'S' | 'N';
};

export function EditServiceOrderModalSecondary({
  isOpen,
  toggleOpen,
  version,
  setVersion,
  setSelectedVersion,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);

  const { setVersions } = useVersions();

  const handleSubmit = useCallback(
    async (data: EditServiceOrderFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nro_ordem: Yup.string().required(),
          status: Yup.string().required(),
          exige_atualizacao_teste: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/version/${version.id}`, data);

        setSelectedVersion(response.data);

        if (setVersion) {
          setVersion(state => ({
            ...state,
            ...response.data,
          }));
        }

        setVersions(mappedVersions =>
          produce(mappedVersions, drafts =>
            drafts.map(draft =>
              draft.id === version.id ? { ...draft, ...response.data } : draft,
            ),
          ),
        );

        toast.success('Ordem de serviço atualizada com sucesso!');

        toggleOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error(err.response?.data.message);
      }
    },
    [setVersions, toggleOpen, version, setVersion, setSelectedVersion],
  );

  const defaultStatusOption = useMemo(() => {
    return getDefaultStatusOption(version);
  }, [version]);

  const defaultMaintenanceOption = useMemo(() => {
    return getDefaultMaintenanceOption(version);
  }, [version]);

  return (
    <Modal isOpen={isOpen} title="Editar ordem de serviço">
      <Form ref={formRef} onSubmit={handleSubmit} initialData={version}>
        <Subtitle>Número da ordem de serviço</Subtitle>
        <Input
          name="nro_ordem"
          placeholder="Código da ordem de serviço"
          type="number"
        />

        <Subtitle>Status</Subtitle>
        <Select
          name="status"
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          options={[
            { label: 'Aprovado', value: 'A' },
            { label: 'Reprovado', value: 'R' },
            { label: 'Pendente', value: 'P' },
          ]}
          isSearchable
          defaultValue={defaultStatusOption}
        />

        <Subtitle>Requer atualização no testes automatizados?</Subtitle>
        <Select
          name="exige_atualizacao_teste"
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          options={[
            { label: 'Sim', value: 'S' },
            { label: 'Não', value: 'N' },
          ]}
          isSearchable
          defaultValue={defaultMaintenanceOption}
        />

        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="check" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
