import { FiPlus } from 'react-icons/fi';

import { Title } from 'components';

import './styles.scss';

type HigherProps = {
  title: string;
  addButtonOnClick?: () => void;
  addButtonText?: string;
};

export function Higher({
  title,
  addButtonOnClick,
  addButtonText,
}: HigherProps) {
  return (
    <div className="higher-container">
      {title && <Title>{title}</Title>}
      {addButtonText && addButtonOnClick && (
        <button
          type="button"
          className="hight-create-button"
          onClick={addButtonOnClick}
        >
          <FiPlus />
          <p>{addButtonText}</p>
        </button>
      )}
    </div>
  );
}
