import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import { FiCheck, FiX } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import {
  BaseButton,
  EditServiceOrderModalSecondary,
  Input,
  Modal,
  Select,
  Step,
  Description,
  Subtitle,
  Textarea,
  Line,
} from 'components';

import { api } from 'services';

import { useSteps, useScenarios, useVersions } from 'hooks';

import { Cenario, OrdemServico, Passo } from 'types';

import {
  getValidationErrors,
  getStatusOption,
  getServiceOrderOptions,
  getMaintenanceOption,
} from 'utils';

import { Buttons } from 'components/Modal/styles';

import { CreateServiceOrderModal } from './CreateServiceOrderModal';

import { DeleteServiceOrderModal } from './DeleteServiceOrderModal';

import { DuplicateServiceOrderModal } from './DuplicateServiceOrderModal';

import { Title, Form, Content } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  scenario: Cenario;
  setSelectedScenario: React.Dispatch<
    React.SetStateAction<Cenario | undefined>
  >;
}

interface EditCaseFormData {
  descr_cenario: string;
}

export function EditScenarioModal({
  isOpen,
  toggleOpen,
  scenario,
  setSelectedScenario,
}: ModalProps) {
  const { setSteps, steps, handleAddStep } = useSteps();
  const { setVersions, versions } = useVersions();

  const { setScenarios } = useScenarios();
  const formRef = useRef<FormHandles>(null);
  const newStepFormRef = useRef<FormHandles>(null);
  const [isEditingSteps, setIsEditingSteps] = useState<Passo[]>([]);

  const [selectedVersion, setSelectedVersion] = useState<
    OrdemServico | undefined
  >(undefined);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [versionStatus, setVersionStatus] = useState('undefined');
  const [versionMaintenance, setVersionMaintenance] = useState('undefined');

  const toggleCreateModalOpen = useCallback(() => {
    setIsCreateModalOpen(state => !state);
  }, []);

  const toggleEditModalOpen = useCallback(() => {
    setIsEditModalOpen(state => !state);
  }, []);

  const toggleDeleteModalOpen = useCallback(() => {
    setIsDeleteModalOpen(state => !state);
  }, []);

  const toggleDuplicateModalOpen = useCallback(() => {
    setIsDuplicateModalOpen(state => !state);
  }, []);

  const handleSubmit = useCallback(
    async (data: EditCaseFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_cenario: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/scenarios/${scenario.id}`, data);

        setSelectedScenario(response.data);

        setScenarios(scenarios =>
          scenarios.map(mappedScenario =>
            mappedScenario.id === response.data.id
              ? response.data
              : mappedScenario,
          ),
        );

        toast.success('Cenário atualizado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error('Falha na alteração do cenário!');
      }
    },
    [setScenarios, scenario.id, setSelectedScenario],
  );

  const handleAddNewStep = useCallback(
    async data => {
      try {
        newStepFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_etapa: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });
        await handleAddStep(selectedVersion?.id ?? null, data.descr_etapa);

        newStepFormRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          newStepFormRef.current?.setErrors(errors);

          setTimeout(() => {
            newStepFormRef.current?.setErrors({});
          }, 3000);
        }
      }
    },
    [handleAddStep, selectedVersion?.id],
  );

  useEffect(() => {
    if (!scenario.id || !isOpen) {
      return;
    }

    api.get(`/versions/${scenario.id}`).then(response => {
      setVersions(response.data);

      setSelectedVersion(response.data[response.data.length - 1] ?? undefined);
    });
  }, [scenario.id, setVersions, isOpen]);

  useEffect(() => {
    if (!selectedVersion || !isOpen) {
      setSteps([]);
      return () => null;
    }

    api.get(`steps/${selectedVersion.id}`).then(response => {
      setSteps(response.data);
    });

    return () => {
      setSteps([]);
      setSelectedVersion(undefined);
    };
  }, [selectedVersion, setSteps, isOpen]);

  useEffect(() => {
    setIsEditingSteps([]);
  }, [selectedVersion?.id]);

  const serviceOrderOptions = useMemo(() => {
    return getServiceOrderOptions(versions);
  }, [versions]);

  const handleServiceOrderChange = useCallback(
    e => {
      setSelectedVersion(() => {
        const version = versions.find(v => v.id === e.value);

        return version;
      });
    },
    [versions],
  );

  useEffect(() => {
    const serviceOrder = versions.find(
      version => version.id === selectedVersion?.id,
    );
    const status = getStatusOption(serviceOrder);
    setVersionStatus(status);

    const maintenance = getMaintenanceOption(serviceOrder);
    setVersionMaintenance(maintenance);
  }, [selectedVersion?.id, versions]);

  return (
    <Modal isOpen={isOpen} width={800}>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={scenario}>
        <Title>Editar cenário</Title>

        <Description>* Nome</Description>

        <Textarea name="descr_cenario" placeholder="Nome do cenário" />

        <Description>Condição</Description>

        <Textarea name="descr_condicao" placeholder="Pré-Condição" />

        <Description>Resultado esperado</Description>

        <Textarea
          name="descr_resultado_esperado"
          placeholder="Resultado esperado"
        />

        <BaseButton model="check" text type="submit" />
      </Form>

      <CreateServiceOrderModal
        isOpen={isCreateModalOpen}
        toggleOpen={toggleCreateModalOpen}
        scenario_id={scenario.id}
        setSelectedVersion={setSelectedVersion}
      />

      {selectedVersion && (
        <>
          <EditServiceOrderModalSecondary
            isOpen={isEditModalOpen}
            toggleOpen={toggleEditModalOpen}
            version={selectedVersion}
            setSelectedVersion={setSelectedVersion}
          />

          <DeleteServiceOrderModal
            isOpen={isDeleteModalOpen}
            toggleOpen={toggleDeleteModalOpen}
            versionId={selectedVersion.id}
            setVersions={setVersions}
            setSteps={setSteps}
            setSelectedVersion={setSelectedVersion}
          />

          <DuplicateServiceOrderModal
            isOpen={isDuplicateModalOpen}
            toggleOpen={toggleDuplicateModalOpen}
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
          />
        </>
      )}

      <Line />
      <Content>
        <header>
          <Subtitle>Ordem de serviço</Subtitle>

          <div>
            <BaseButton model="plus" onClick={toggleCreateModalOpen} text />

            <BaseButton
              model="edit"
              onClick={toggleEditModalOpen}
              text
              disabled={!selectedVersion}
            />

            <BaseButton
              model="duplicate"
              onClick={toggleDuplicateModalOpen}
              text
              disabled={!selectedVersion}
            />

            <BaseButton
              model="delete"
              onClick={toggleDeleteModalOpen}
              text
              disabled={!selectedVersion}
            />
          </div>
        </header>

        <Select
          options={serviceOrderOptions}
          isSearchable
          value={{
            label:
              selectedVersion?.nro_ordem || 'Selecione alguma ordem de serviço',
            value: selectedVersion?.id || null,
          }}
          onChange={handleServiceOrderChange}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          noOptionsMessage={() => 'Nenhuma ordem de serviço encontrada!'}
        />

        {selectedVersion && (
          <>
            <Description>Status</Description>
            <div style={{ display: 'flex' }}>
              {versionStatus === 'Aprovado' && (
                <FiCheck color="#55a630" size={20} />
              )}
              {versionStatus === 'Reprovado' && (
                <FiX color="#e63946" size={20} />
              )}
              {versionStatus === 'Pendente' && (
                <IoHelp color="#f4a261" size={20} />
              )}

              <span>{versionStatus}</span>
            </div>

            <Description>
              Requer atualização nos testes automatizados?
            </Description>
            <div style={{ display: 'flex' }}>
              <p>{versionMaintenance}</p>
            </div>
          </>
        )}
      </Content>

      <Line />

      <Subtitle>Etapas</Subtitle>

      {steps.map((step, index) => (
        <Step
          key={step.id}
          step={step}
          index={index}
          viewMode={false}
          isEditing={
            !!isEditingSteps.find(editingStep => editingStep.id === step.id)
          }
          setIsEditingSteps={setIsEditingSteps}
        />
      ))}

      {selectedVersion && (
        <Form ref={newStepFormRef} onSubmit={handleAddNewStep}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input name="descr_etapa" placeholder="Descrição da nova etapa" />
            <BaseButton
              model="plus"
              text={false}
              type="submit"
              style={{ marginLeft: '10px', marginTop: 0 }}
            />
          </div>
        </Form>
      )}

      <Line />

      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />
        {selectedVersion?.id && (
          <Link to={`/problems/${selectedVersion.id}`}>
            <BaseButton model="problem" text />
          </Link>
        )}
      </Buttons>
    </Modal>
  );
}
