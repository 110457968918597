import { useCallback, useMemo, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import {
  BaseButton,
  Input,
  Modal,
  Subtitle,
  Line,
  SelectWithUnform as Select,
} from 'components';

import { api } from 'services';

import { useScenarios } from 'hooks';

import { getValidationErrors } from 'utils';

import { Cenario } from 'types';

import { Buttons } from 'components/Modal/styles';

import { Title } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  selectedScenario: Cenario;
  setSelectedScenario: React.Dispatch<
    React.SetStateAction<Cenario | undefined>
  >;
}

interface DuplicateScenarioFormData {
  descr_cenario: string;
  base_scenario_id: string;
}

export function DuplicateScenarioModal({
  isOpen,
  toggleOpen,
  selectedScenario,
  setSelectedScenario,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);

  const { scenarios, setScenarios } = useScenarios();

  const handleSubmit = useCallback(
    async (data: DuplicateScenarioFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_cenario: Yup.string().required(),
          base_scenario_id: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post('/scenarios/duplicate', {
          descr_cenario: data.descr_cenario,
          base_scenario_id: data.base_scenario_id,
        });

        setScenarios(state => [response.data, ...state]);

        setSelectedScenario(response.data);

        toggleOpen();

        toast.success('Cenário duplicado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho!');

          return;
        }

        toast.error('Falha na criação do cenário!');
      }
    },
    [setScenarios, setSelectedScenario, toggleOpen],
  );

  const cenariosOptions = useMemo(() => {
    return scenarios.map(scnr => ({
      value: scnr.id,
      label: scnr.descr_cenario,
    }));
  }, [scenarios]);

  const defaultScenarioOption = useMemo(() => {
    if (selectedScenario.id) {
      return {
        value: selectedScenario.id,
        label: selectedScenario.descr_cenario,
      };
    }
    return null;
  }, [selectedScenario]);

  return (
    <Modal isOpen={isOpen}>
      <Title>Duplicar cenário</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Subtitle>* Nome do Cenário</Subtitle>
        <Input name="descr_cenario" placeholder="Nome do cenário" />

        <Subtitle>* Cenário a ser duplicado</Subtitle>
        <Select
          name="base_scenario_id"
          options={cenariosOptions}
          isSearchable
          defaultValue={defaultScenarioOption}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          noOptionsMessage={() => 'Nenhum cenário encontrado!'}
        />

        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="plus" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
