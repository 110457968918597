import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import { Modal, BaseButton, Line } from 'components';

import { api } from 'services';

import { OrdemServico, Passo } from 'types';

import { Buttons } from 'components/Modal/styles';

import './styles.scss';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  versionId: number;
  setVersions: React.Dispatch<React.SetStateAction<OrdemServico[]>>;
  setSteps: React.Dispatch<React.SetStateAction<Passo[]>>;
  setSelectedVersion: React.Dispatch<
    React.SetStateAction<OrdemServico | undefined>
  >;
};

export function DeleteServiceOrderModal({
  isOpen,
  toggleOpen,
  versionId,
  setVersions,
  setSteps,
  setSelectedVersion,
}: ModalProps) {
  const deleteModule = useCallback(async () => {
    try {
      await api.delete(`/version/${versionId}`);

      setVersions(versions =>
        versions.filter(version => version.id !== versionId),
      );

      setSteps([]);

      setSelectedVersion(undefined);

      toggleOpen();

      toast.success('Ordem de serviço deletada com sucesso!');
    } catch {
      toast.error('Falha ao deletar ordem de serviço!');
    }
  }, [toggleOpen, setSelectedVersion, setVersions, versionId, setSteps]);

  return (
    <Modal isOpen={isOpen} title="Atenção">
      <div className="delete-service-order">
        <p>Tem certeza que deseja excluir este registro?</p>
      </div>

      <Line />

      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />
        <BaseButton model="check" onClick={deleteModule} text />
      </Buttons>
    </Modal>
  );
}
