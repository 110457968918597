import React, { useEffect, useState } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import {
  BaseButton,
  Modal,
  Step,
  Description,
  Subtitle,
  Line,
} from 'components';

import { api } from 'services';

import { useSteps } from 'hooks';

import { getStatusOption } from 'utils';

import { Cenario, OrdemServico } from 'types';

import { Steps } from './styles';

import { Buttons } from '../../../components/Modal/styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  ordemServico: OrdemServico;
}

export function ViewServiceOrderModal({
  isOpen,
  toggleOpen,
  ordemServico,
}: ModalProps) {
  const { setSteps, steps } = useSteps();
  const [scenario, setScenario] = useState<Cenario>({} as Cenario);

  const [versionStatus, setVersionStatus] = useState('undefined');

  useEffect(() => {
    if (!ordemServico.id_cenario_teste || !isOpen) {
      return;
    }

    api.get(`/scenario/${ordemServico.id_cenario_teste}`).then(response => {
      setScenario(response.data);
    });
  }, [ordemServico.id_cenario_teste, isOpen]);

  useEffect(() => {
    if (!ordemServico.id || !isOpen) {
      return;
    }

    api.get(`steps/${ordemServico.id}`).then(response => {
      setSteps(response.data);
    });
  }, [ordemServico.id, setSteps, isOpen]);

  useEffect(() => {
    const status = getStatusOption(ordemServico);
    setVersionStatus(status);
  }, [ordemServico]);

  return (
    <Modal isOpen={isOpen} width={800} title="Visualizar ordem de serviço">
      <Description>Nome</Description>

      <p>{scenario.descr_cenario}</p>

      <Description>Condição</Description>

      <p style={{ whiteSpace: 'pre-wrap' }}>{scenario.descr_condicao}</p>

      <Description>Resultado esperado</Description>

      <p style={{ whiteSpace: 'pre-wrap' }}>
        {scenario.descr_resultado_esperado}
      </p>

      <Line />

      <Description>Status</Description>
      <div style={{ display: 'flex' }}>
        {ordemServico.status === 'A' && <FiCheck color="#55a630" size={20} />}
        {ordemServico.status === 'R' && <FiX color="#e63946" size={20} />}
        {ordemServico.status === 'P' && <IoHelp color="#f4a261" size={20} />}

        <span>{versionStatus}</span>
      </div>

      <Description>Requer atualização no testes automatizados?</Description>
      <div style={{ display: 'flex' }}>
        {ordemServico.exige_atualizacao_teste === 'S' && <p>Sim</p>}
        {ordemServico.exige_atualizacao_teste === 'N' && <p>Não</p>}
      </div>

      <Line />

      <Steps>
        <Subtitle>Etapas</Subtitle>

        {steps?.map((step, index) => (
          <Step
            key={step.id}
            step={step}
            index={index}
            viewMode
            isEditing={false}
          />
        ))}
      </Steps>

      <Line />

      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />

        <Link to={`/problems/${ordemServico.id}`}>
          <BaseButton model="problem" text />
        </Link>
      </Buttons>
    </Modal>
  );
}
