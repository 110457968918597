import { ReactNode } from 'react';
import { Loading } from 'components';

import './styles.scss';

type PageProps = {
  loading?: boolean;
  children: ReactNode;
};

export function Page({ children, loading }: PageProps) {
  if (loading) {
    return (
      <div className="page-container">
        <Loading />
      </div>
    );
  }

  return (
    <div className="page-container">
      <main>{children}</main>
    </div>
  );
}
