import { shade } from 'polished';
import styled from 'styled-components';

export const GoBack = styled.div`
  display: flex;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;

  a {
    text-decoration: none;
    color: #fff;
    transition: 0.2s;

    &:hover {
      span,
      svg {
        color: ${shade(0.2, '#fff')};
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding: 0 20px 0 15px;

    span {
      transition: 0.2s;
    }

    svg {
      width: 20px;
      height: 20px;
      transition: 0.2s;
    }
  }
`;
