import * as msal from '@azure/msal-browser';

const msalInstance = new msal.PublicClientApplication({
  auth: {
    clientId: 'dc9ad766-d0ea-4a06-a4ca-198c3bd1c186',
    authority:
      'https://login.microsoftonline.com/719713b4-2cb6-460e-83e7-98247e58a57d/',
    redirectUri: process.env.REACT_APP_WEB_URL,
  },
});

export { msalInstance };
