import styled from 'styled-components';

export const Component = styled.div`
  text-align: center;
  color: white;
  padding-top: 3px;
  margin-left: 10px;
  text-transform: uppercase;
  font-weight: 600;
`;
