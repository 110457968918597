import React, { createContext, useState, useContext } from 'react';

import { Programa } from 'types';

interface ScreensContextData {
  screens: Programa[];
  setScreens: React.Dispatch<React.SetStateAction<Programa[]>>;
}

const ScreensContext = createContext<ScreensContextData>(
  {} as ScreensContextData,
);

const ScreenProvider: React.FC = ({ children }) => {
  const [screens, setScreens] = useState<Programa[]>([]);

  return (
    <ScreensContext.Provider
      value={{
        screens,
        setScreens,
      }}
    >
      {children}
    </ScreensContext.Provider>
  );
};

function useScreens(): ScreensContextData {
  const context = useContext(ScreensContext);

  return context;
}

export { ScreenProvider, useScreens };
