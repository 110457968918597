import styled from 'styled-components';

export const Content = styled.div`
  color: #fff;

  form + & {
    margin-top: 10px;
  }

  header {
    display: flex;
    flex-direction: column;

    div {
      display: flex;

      button {
        svg {
          width: 23px;
        }
      }

      button + button {
        margin-left: 15px;
      }
    }
  }
`;
