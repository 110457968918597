/* eslint-disable import/no-duplicates */

import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { Higher, NavigationTree, Page, BaseButton } from 'components';

import { api } from 'services';

import { useAuth } from 'hooks';

import { Modulo, Projeto } from 'types';

import { getDefaultStatusOption } from 'utils';
import { CreateModuleModal } from './CreateModuleModal';

import { EditModuleModal } from './EditModuleModal';

import { DeleteModuleModal } from './DeleteModuleModal';

interface Params {
  id: string;
}

export function Modules() {
  const { id } = useParams<Params>();

  const history = useHistory();

  const [loadingProject, setLoadingProject] = useState(true);
  const [loadingModules, setLoadingModules] = useState(true);

  const [project, setProject] = useState<Projeto>({} as Projeto);
  const [modules, setModules] = useState<Modulo[]>([]);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [selectedModule, setSelectedModule] = useState<Modulo | undefined>(
    undefined,
  );

  const { signOut } = useAuth();

  const toggleCreateModalOpen = useCallback(() => {
    setIsCreateModalOpen(state => !state);
  }, []);

  const toggleEditModalOpen = useCallback(() => {
    setIsEditModalOpen(state => !state);
  }, []);

  const toggleDeleteModalOpen = useCallback(() => {
    setIsDeleteModalOpen(state => !state);
  }, []);

  useEffect(() => {
    document.title = 'Módulos - Test Soft';

    api
      .get(`project/${id}`)
      .then(response => {
        setProject(response.data);
      })
      .catch(err => {
        if (err.response?.data.newTokenRequired) {
          toast.error('Sessão expirada!');
          signOut();
        }
      })
      .finally(() => setLoadingProject(false));
  }, [id, signOut]);

  useEffect(() => {
    api
      .get(`modules/${id}`)
      .then(response => {
        setModules(response.data);
      })
      .finally(() => setLoadingModules(false));
  }, [id, signOut]);

  return (
    <Page loading={loadingProject || loadingModules}>
      <Higher title="Módulos" />

      {project.descr_projeto && (
        <NavigationTree>
          <Link to={`/modules/${project.id}`}>
            <span>{project.descr_projeto}</span>
          </Link>
        </NavigationTree>
      )}

      <CreateModuleModal
        isOpen={isCreateModalOpen}
        toggleOpen={toggleCreateModalOpen}
        projectId={project.id}
        setModules={setModules}
      />

      {selectedModule && (
        <>
          <EditModuleModal
            isOpen={isEditModalOpen}
            toggleOpen={toggleEditModalOpen}
            module={selectedModule}
            setModules={setModules}
            setSelectedModule={setSelectedModule}
          />

          <DeleteModuleModal
            isOpen={isDeleteModalOpen}
            toggleOpen={toggleDeleteModalOpen}
            moduleId={selectedModule.id}
            setModules={setModules}
            setSelectedModule={setSelectedModule}
          />
        </>
      )}

      <div className="tools-buttons">
        <BaseButton
          model="plus"
          text
          onClick={() => {
            toggleCreateModalOpen();
          }}
        />

        <BaseButton
          model="edit"
          text
          onClick={toggleEditModalOpen}
          disabled={!selectedModule}
        />
        <BaseButton
          model="delete"
          text
          onClick={toggleDeleteModalOpen}
          disabled={!selectedModule}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Nome do módulo</th>
              <th>Status</th>
              <th>Atualizado há</th>
              <th>Acessar</th>
            </tr>
          </thead>

          <tbody>
            {modules.map(module => (
              <tr
                key={module.id}
                onClick={() => {
                  if (selectedModule?.id === module.id) {
                    setSelectedModule(undefined);
                  } else {
                    setSelectedModule(module);
                  }
                }}
                onDoubleClick={() => history.push(`/screens/${module.id}`)}
                className={
                  module.id === selectedModule?.id
                    ? 'selected-row'
                    : 'not-selected-row'
                }
              >
                <td>{module.descr_modulo}</td>
                <td
                  style={{
                    color:
                      (module.status === 'A' && 'var(--success)') ||
                      (module.status === 'R' && 'var(--alert)') ||
                      'var(--accent)',
                  }}
                >
                  {getDefaultStatusOption(module)?.label || 'Não definido'}
                </td>
                <td>
                  {module.data_atualizacao &&
                    formatDistance(
                      new Date(),
                      new Date(module.data_atualizacao),
                      {
                        locale: ptBR,
                      },
                    )}
                </td>
                <td>
                  <BaseButton
                    model="upRight"
                    onClick={() => history.push(`/screens/${module.id}`)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {modules.length <= 0 && (
          <p style={{ marginTop: 30 }}>Não há registros cadastrados!</p>
        )}
      </div>
    </Page>
  );
}
