/* eslint-disable react/jsx-filename-extension */
import options from './helper';

import './styles.scss';

export function BaseButton({ model, text, ...rest }) {
  const { Icon, label, color } = options[model];

  return (
    <button
      className={`base-button${text ? ' button-with-text' : ''}`}
      type="button"
      {...rest}
    >
      <Icon color={color} />
      {text && <span>{label}</span>}
    </button>
  );
}
