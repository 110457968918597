import React, { createContext, useState, useContext } from 'react';

const ColaboradoresContext = createContext();

const ColaboradoresProvider = ({ children }) => {
  const [colaboradores, setColaboradores] = useState([]);

  return (
    <ColaboradoresContext.Provider
      value={{
        colaboradores,
        setColaboradores,
      }}
    >
      {children}
    </ColaboradoresContext.Provider>
  );
};

function useColaboradores() {
  const context = useContext(ColaboradoresContext);

  return context;
}

export { ColaboradoresProvider, useColaboradores };
