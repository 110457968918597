import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { BaseButton, Input, Modal, Subtitle, Line } from 'components';

import { api } from 'services';

import { Modulo } from 'types';

import { getValidationErrors } from 'utils';

import { Buttons } from 'components/Modal/styles';

import { Title } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  projectId: number;
  setModules: React.Dispatch<React.SetStateAction<Modulo[]>>;
}

interface CreateModuleFormData {
  descr_modulo: string;
}

export function CreateModuleModal({
  isOpen,
  toggleOpen,
  projectId,
  setModules,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: CreateModuleFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_modulo: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post('/module', {
          descr_modulo: data.descr_modulo,
          id_projeto_cenario_teste: projectId,
        });

        setModules(modules => [response.data, ...modules]);

        toggleOpen();

        toast.success('Módulo criado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho!');

          return;
        }

        toast.error('Falha na criação do módulo!');
      }
    },
    [toggleOpen, projectId, setModules],
  );

  return (
    <Modal isOpen={isOpen}>
      <Title>Criar módulo</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Subtitle>* Nome do Módulo</Subtitle>
        <Input name="descr_modulo" placeholder="Nome do módulo" />

        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="plus" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
