import styled from 'styled-components';

export const Title = styled.strong`
  display: flex;
  margin: 0 0 15px 0;
  color: #fff;
`;

export const Subtitle = styled.strong`
  display: flex;
  padding: 5px 0 10px 0;
  font-size: 14px;
  color: #fff;

  & + p {
    padding: 0 0 10px 0;
  }
`;
