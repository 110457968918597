/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { api } from 'services';

// import api from '../../services/api';

class ReportSolicitacaoAtrasada {
  constructor(filtros) {
    this.doc = new jsPDF();
    this.filtros = filtros;
    this.posicaoY = 20;
  }

  async gerarDocumento() {
    const me = this;

    const { dadosSolucao, dadosVersao } = await me.buscaDados();

    const rowsSolucao = [];
    const rowsVersao = [];

    me.doc.setFontSize(12);
    me.doc.text('Quantidade de bugs lançados', 14, me.posicaoY);
    me.addNovaLinhaNormal();

    me.doc.setFontSize(10);

    me.addLinhaPeriodo();
    me.addLinhaSolucao();

    let total = 0;

    dadosSolucao.forEach(registro => {
      const valoresRegistro = [registro.SOLUCAO, registro.SOLICITACOES];
      total += registro.SOLICITACOES;
      rowsSolucao.push(valoresRegistro);
    });
    if (rowsSolucao.length > 1) {
      rowsSolucao.push(['Total', total]);
    }
    me.doc.autoTable({
      startY: me.posicaoY,
      head: [['Solução', 'Bugs Lançados']],
      body: rowsSolucao,
    });

    me.posicaoY = me.doc.lastAutoTable.finalY + 5;

    if (!me.filtros.versao) {
      let totalVersao = 0;
      dadosVersao.VERSOES.forEach(registro => {
        totalVersao += registro.SOLICITACOES;
        rowsVersao.push([registro.VERSAO, registro.SOLICITACOES]);
      });
      if (rowsVersao.length > 1) {
        rowsVersao.push(['Total', totalVersao]);
      }
      me.doc.autoTable({
        startY: me.posicaoY + 5,
        head: [['Versão', 'Bugs Lançados']],
        body: rowsVersao,
      });
    }

    me.posicaoY = me.doc.lastAutoTable.finalY + 5;

    me.doc.save('Bugs lançados.pdf');
  }

  async buscaDados() {
    const me = this;
    const response = await api.get('bugsLancados/pdf', {
      params: me.filtros,
    });

    return response.data;
  }

  addNovaLinhaNormal() {
    this.posicaoY += 5;
  }

  strDate(date) {
    const dia = date.substring(8, 10);
    const mes = date.substring(5, 7);
    const ano = date.substring(0, 4);
    return `${dia}/${mes}/${ano}`;
  }

  addLinhaPeriodo = () => {
    const me = this;
    const { filtros } = me;
    const ano = `Ano: ${filtros.ano}`;
    let dataInicial = '';
    let dataFinal = '';

    if (filtros.dataInicial) {
      dataInicial = `    Data Inicial: ${me.strDate(filtros.dataInicial)}`;
    }

    if (filtros.dataFinal) {
      dataFinal = `    Data Final: ${me.strDate(filtros.dataFinal)}`;
    }

    me.doc.text(`${ano}${dataInicial}${dataFinal}`, 14, me.posicaoY);
    me.addNovaLinhaNormal();
  };

  addLinhaSolucao = () => {
    const me = this;
    const { filtros } = me;
    let solucao = '';
    let versao = '';

    if (filtros.solucao) {
      solucao = `Solução: ${filtros.solucao}`;
    }

    if (filtros.versao) {
      const gap = solucao ? '    ' : '';
      versao = `${gap}Versão: ${filtros.descritivoVersao}`;
    }

    if (solucao || versao) {
      me.doc.text(`${solucao}${versao}`, 14, me.posicaoY);
      me.addNovaLinhaNormal();
    }
  };
}

export default ReportSolicitacaoAtrasada;
