import React, { createContext, useCallback, useState, useContext } from 'react';

import { Usuario } from 'types';

import { api, msalInstance } from 'services';
import { api as apiJS } from 'services/api.jsx';

interface AuthState {
  token: string;
  usuario: Usuario;
}

interface SignInCredentials {
  email: string;
  senha: string;
}

interface AuthContextData {
  usuario: Usuario;
  token: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  microsoftSignIn(): Promise<void>;
  signOut(): void;
  updateUser(user: Usuario): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@tests:token');
    const user = localStorage.getItem('@tests:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      apiJS.defaults.headers.authorization = `Bearer ${token}`;
      return { token, usuario: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, senha }) => {
    const response = await api.post('auth', {
      email,
      senha,
    });

    const { token, user } = response.data;

    localStorage.setItem('@tests:token', token);
    localStorage.setItem('@tests:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    apiJS.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, usuario: user });
  }, []);

  const microsoftSignIn = useCallback(async () => {
    const res = await msalInstance.loginPopup({
      scopes: ['openid', 'offline_access', 'profile', 'user.read'],
      prompt: 'select_account',
    });

    const response = await api.post('auth/microsoft', {
      token: res.accessToken,
    });

    const { token, user } = response.data;

    localStorage.setItem('@tests:token', token);
    localStorage.setItem('@tests:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    apiJS.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, usuario: user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@tests:token');
    localStorage.removeItem('@tests:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (usuario: Usuario) => {
      localStorage.setItem('@tests:user', JSON.stringify(usuario));

      setData({
        token: data.token,
        usuario,
      });
    },
    [data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        usuario: data.usuario,
        signIn,
        signOut,
        microsoftSignIn,
        token: data.token,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
