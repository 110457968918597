import React, { createContext, useState, useContext } from 'react';

const AnosContext = createContext();

const getUltimosAnos = () => {
  const anos = [];
  const atual = new Date().getFullYear();
  let retroceder = 20;

  while (retroceder > 0) {
    anos.push(atual - retroceder);
    retroceder -= 1;
  }
  anos.push(atual);
  return anos.reverse();
};

const AnosProvider = ({ children }) => {
  const [anos, setAnos] = useState(getUltimosAnos());

  return (
    <AnosContext.Provider
      value={{
        anos,
        setAnos,
      }}
    >
      {children}
    </AnosContext.Provider>
  );
};

function useAnos() {
  const context = useContext(AnosContext);

  return context;
}

export { AnosProvider, useAnos };
