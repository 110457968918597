import styled, { css } from 'styled-components';

import { COLORS } from 'styles';

type ContainerProps = {
  isErrored: boolean;
  isFocused: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  border: 1px solid ${COLORS.BACKGROUND_DARK};
  border-radius: 5px;
  padding: 10px 10px;
  line-height: 26px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: border 0.2s ease 0s;
  background: ${COLORS.BACKGROUND_LIGHT};

  div + & {
    margin-top: 14px;
  }

  ${props =>
    props.isFocused &&
    css`
      border-color: ${COLORS.LIGHTEN_15};
    `}

  ${props =>
    props.isErrored &&
    css`
      border-color: ${COLORS.ALERT};
    `}

  input {
    font-size: 16px;
    flex: 1;
    background: none;
    border: 0;
    color: ${COLORS.FONT};
  }

  svg {
    margin-right: 16px;
  }
`;
