import React from 'react';
import { Component, Wrapper } from './styles.jsx';

function ContentNotFound() {
  return (
    <Wrapper>
      <Component>
        Nenhum conteúdo encontrado com base nos filtros aplicados
      </Component>
    </Wrapper>
  );
}

export default ContentNotFound;
