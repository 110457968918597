import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import {
  BaseButton,
  Input,
  Modal,
  Subtitle,
  Line,
  SearchScreenModal,
} from 'components';

import { api } from 'services';

import { useScreens } from 'hooks';

import { getValidationErrors } from 'utils';

import { Buttons } from 'components/Modal/styles';

import { Title } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  moduleId: string;
}

interface CreateScreenFormData {
  descr_programa: string;
  cod_programa: string;
}

const CreateScreenModal: React.FC<ModalProps> = ({
  isOpen,
  toggleOpen,
  moduleId,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const toggleSeatchModalOpen = useCallback(() => {
    setIsSearchModalOpen(state => !state);
  }, []);

  const { setScreens } = useScreens();

  const handleSubmit = useCallback(
    async (data: CreateScreenFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_programa: Yup.string().required(),
          cod_programa: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post('/screen', {
          descr_programa: data.descr_programa,
          cod_programa: data.cod_programa,
          id_modulo_cenario_teste: moduleId,
        });

        setScreens(state => [response.data, ...state]);

        toggleOpen();

        toast.success('Tela criada com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho!');

          return;
        }

        toast.error(err.response.data.message);
      }
    },
    [toggleOpen, moduleId, setScreens],
  );

  return (
    <Modal isOpen={isOpen}>
      <SearchScreenModal
        isOpen={isSearchModalOpen}
        toggleOpen={toggleSeatchModalOpen}
        setInputValue={formRef.current?.setFieldValue}
      />
      <Title>Criar tela</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Subtitle>* Código da tela</Subtitle>
        <div style={{ display: 'flex' }}>
          <Input name="cod_programa" placeholder="Código" uppercase />
          <BaseButton
            model="search"
            style={{ marginLeft: 10 }}
            onClick={toggleSeatchModalOpen}
          />
        </div>

        <Subtitle>* Nome da tela</Subtitle>
        <Input name="descr_programa" placeholder="Nome" />

        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="plus" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
};

export { CreateScreenModal };
