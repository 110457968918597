import { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import {
  BaseButton,
  Input,
  Modal,
  Subtitle,
  Line,
  SearchScreenModal,
} from 'components';

import { api } from 'services';

import { useScreens } from 'hooks';

import { Programa } from 'types';

import { getValidationErrors } from 'utils';

import { Buttons } from 'components/Modal/styles';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  screen: Programa;
  setSelectedScreen: React.Dispatch<React.SetStateAction<Programa>>;
};

type EditScreenFormData = {
  cod_programa: string;
  descr_programa: string;
};

export function EditScreenModal({
  isOpen,
  toggleOpen,
  screen,
  setSelectedScreen,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);
  const { setScreens } = useScreens();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const toggleSeatchModalOpen = useCallback(() => {
    setIsSearchModalOpen(state => !state);
  }, []);

  const handleSubmit = useCallback(
    async (data: EditScreenFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cod_programa: Yup.string().required(),
          descr_programa: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/screen/${screen.id}`, data);

        setSelectedScreen(response.data);

        setScreens(state =>
          state.map(mappedScreen =>
            mappedScreen.id === screen.id ? response.data : mappedScreen,
          ),
        );

        toggleOpen();

        toast.success('Tela atualizada com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho!');

          return;
        }

        toast.error(err.response.data.message);
      }
    },
    [setScreens, toggleOpen, screen, setSelectedScreen],
  );

  return (
    <Modal isOpen={isOpen} title="Editar tela">
      <SearchScreenModal
        isOpen={isSearchModalOpen}
        toggleOpen={toggleSeatchModalOpen}
        setInputValue={formRef.current?.setFieldValue}
      />

      <Form ref={formRef} onSubmit={handleSubmit} initialData={screen}>
        <Subtitle>* Código da tela</Subtitle>
        <div style={{ display: 'flex' }}>
          <Input name="cod_programa" placeholder="Código" uppercase />
          <BaseButton
            model="search"
            style={{ marginLeft: 10 }}
            onClick={toggleSeatchModalOpen}
          />
        </div>

        <Subtitle>* Nome da tela</Subtitle>
        <Input name="descr_programa" placeholder="Nome" />

        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="check" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
