import React, { createContext, useState, useContext } from 'react';
import { api } from 'services';

const SolucoesContext = createContext();

const SolucoesProvider = ({ children }) => {
  const [solucoes, setSolucoes] = useState([]);

  return (
    <SolucoesContext.Provider
      value={{
        solucoes,
        setSolucoes,
      }}
    >
      {children}
    </SolucoesContext.Provider>
  );
};

function useSolucoes() {
  const context = useContext(SolucoesContext);

  return context;
}

export { SolucoesProvider, useSolucoes };
