import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  TextareaHTMLAttributes,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  containerStyle?: React.CSSProperties;
}

export function Textarea({ containerStyle, name, ...rest }: TextareaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, error, defaultValue, registerField } = useField(name);

  const handleTextareaBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleTextareaFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleValueChange = useCallback(() => {
    if (textareaRef.current) {
      const offset =
        textareaRef.current.offsetHeight - textareaRef.current.clientHeight;

      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight + offset
      }px`;
    }
  }, []);

  useEffect(() => {
    handleValueChange();
  }, [handleValueChange]);

  return (
    <Container style={containerStyle} isErrored={!!error} isFocused={isFocused}>
      <textarea
        defaultValue={defaultValue}
        onBlur={handleTextareaBlur}
        onFocus={handleTextareaFocus}
        ref={textareaRef}
        onChange={handleValueChange}
        {...rest}
      />
    </Container>
  );
}
