import styled, { css } from 'styled-components';

import { COLORS } from 'styles';

type ContainerProps = {
  isDragging: boolean;
  isEditing: boolean;
  viewMode: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 10px;
  margin: 5px 0;
  cursor: ${props => (props.viewMode ? 'normal' : 'grab')};
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background: ${COLORS.BACKGROUND};

  > div {
    display: flex;

    button {
      margin: 0;
      padding: 0 5px;
    }
  }

  svg {
    width: 20px;
  }

  ${props =>
    props.isEditing &&
    css`
      border: 1px solid #595959;
      border-radius: 5px;
      background: ${COLORS.BACKGROUND_LIGHT};
    `}

  > form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    p {
      color: #ffff;
      display: flex;
      text-align: center;
      align-items: center;
    }

    > div {
      margin-left: 4px;
      display: flex;
      align-items: center;
      border: 0;
      padding: 0;
      margin: 0;

      box-shadow: none;

      button {
        margin: 0;
        padding: 0 5px;
      }
    }
  }

  p {
    max-width: 80%;
  }

  ${props =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: transparent;
      box-shadow: none;
      cursor: grabbing;

      p {
        opacity: 0;
      }
    `}
`;
