import React from 'react';
import { Switch } from 'react-router-dom';

import {
  ForgotPassword,
  Modules,
  NotFound,
  Problems,
  Profile,
  Projects,
  ResetPassword,
  Scenarios,
  Screens,
  SignIn,
  SignUp,
  Versions,
} from 'pages';

import { Metricas } from 'pages/Metricas/index.jsx';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/projects" component={Projects} isPrivate />
    <Route path="/modules/:id" component={Modules} isPrivate />
    <Route path="/screens/:id" component={Screens} isPrivate />
    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/problems/:id" component={Problems} isPrivate />
    <Route path="/scenarios/:id" component={Scenarios} isPrivate />
    <Route path="/versions" component={Versions} isPrivate />
    <Route path="/metricas" component={Metricas} isPrivate isMetrics />

    <Route path="/forgot" component={ForgotPassword} />
    <Route path="/reset-password/:token?" component={ResetPassword} />

    <Route component={NotFound} isPrivate />
  </Switch>
);

export default Routes;
