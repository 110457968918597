import { OrdemServico } from 'types';

type IResponse = {
  value: string;
  label: string;
};

function getDefaultMaintenanceOption(
  ordemServico: OrdemServico,
): IResponse | null {
  switch (ordemServico.exige_atualizacao_teste) {
    case 'S': {
      return {
        value: 'S',
        label: 'Sim',
      };
    }
    case 'N': {
      return {
        value: 'N',
        label: 'Não',
      };
    }
    default: {
      return null;
    }
  }
}

export { getDefaultMaintenanceOption };
