import React, { useCallback, useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { LargeButton, Input } from 'components';

import { getValidationErrors } from 'utils';
import { api } from 'services';

import { COLORS } from 'styles';
import { Container } from './styles';

interface ResetPasswordFormData {
  senha: string;
  confirmacao_senha: string;
}

export function ResetPassword() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = 'Redefinir Senha - Test Soft';
  }, []);

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          senha: Yup.string().required().min(6),
          confirmacao_senha: Yup.string().oneOf(
            [Yup.ref('senha'), undefined],
            'Passwords must match',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        const { senha, confirmacao_senha } = data;

        const token = location.search.replace('?token=', '');

        if (!token) {
          toast.error('Token inválido.');
          return;
        }

        await api.post('password/reset', {
          senha,
          confirmacao_senha,
          token,
        });

        toast.success('Sua senha for alterada.');

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error('Erro ao redefinir senha.');
      }
    },
    [history, location.search],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <h1>Redefinir senha</h1>

        <Input name="senha" type="password" placeholder="Senha" />

        <Input
          name="confirmacao_senha"
          type="password"
          placeholder="Confirmação de senha"
        />

        <LargeButton
          type="submit"
          color={COLORS.ACCENT}
          title="Alterar senha"
        />

        <span>
          <Link to="/">Voltar</Link>
        </span>
      </Form>
    </Container>
  );
}
