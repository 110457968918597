import { ReactNode } from 'react';

import './styles.scss';

type DescriptionProps = {
  children: ReactNode;
};

export function Description({ children }: DescriptionProps) {
  return <strong className="input-description">{children}</strong>;
}
