import { useRef, useEffect } from 'react';
import ReactSelect, { OptionTypeBase, Props, Theme } from 'react-select';
import { useField } from '@unform/core';

import './styles.scss';

interface SelectProps extends Props<OptionTypeBase> {
  name: string;
}

export function SelectWithUnform({ name, ...rest }: SelectProps) {
  const selectRef = useRef(null);
  const { fieldName, error, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <ReactSelect
      defaultValue={defaultValue}
      ref={selectRef}
      className={error && 'select-with-error'}
      theme={(theme: Theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#000',
          primary50: '#111',
        },
      })}
      classNamePrefix="select"
      {...rest}
    />
  );
}
