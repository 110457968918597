import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppProvider } from 'hooks';

import 'react-toastify/dist/ReactToastify.css';
import './styles/global.scss';
import './styles/keyframes.scss';
import './styles/tables.scss';

import Routes from './routes';

const App: React.FC = () => (
  <BrowserRouter>
    <AppProvider>
      <Routes />
      <ToastContainer autoClose={3000} />
    </AppProvider>
  </BrowserRouter>
);

export default App;
