import { useCallback, useEffect, useRef, useState } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { IoHelp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import {
  BaseButton,
  EditServiceOrderModalSecondary,
  Modal,
  Step,
  Description,
  Subtitle,
  Line,
  Input,
} from 'components';

import { api } from 'services';

import { useSteps } from 'hooks';

import { OrdemServico, Cenario, Passo } from 'types';

import { Buttons } from 'components/Modal/styles';

import { Form } from '@unform/web';
import { getValidationErrors } from 'utils';
import { Content } from './styles';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  version_id: number;
};

export function EditServiceOrderModalPrimary({
  isOpen,
  toggleOpen,
  version_id,
}: ModalProps) {
  const newStepFormRef = useRef<FormHandles>(null);

  const { setSteps, steps, handleAddStep } = useSteps();
  const [scenario, setScenario] = useState<Cenario>({} as Cenario);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [version, setVersion] = useState<OrdemServico | undefined>(undefined);

  const [isEditingSteps, setIsEditingSteps] = useState<Passo[]>([]);

  const toggleEditModalOpen = useCallback(() => {
    setIsEditModalOpen(state => !state);
  }, []);

  const handleAddNewStep = useCallback(
    async data => {
      try {
        newStepFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_etapa: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });
        await handleAddStep(version?.id ?? null, data.descr_etapa);

        newStepFormRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          newStepFormRef.current?.setErrors(errors);

          setTimeout(() => {
            newStepFormRef.current?.setErrors({});
          }, 3000);
        }
      }
    },
    [handleAddStep, version?.id],
  );

  useEffect(() => {
    if (!version_id || !isOpen) {
      return;
    }

    api.get(`/version/${version_id}`).then(response => {
      setVersion(response.data);
    });
  }, [version_id, isOpen]);

  useEffect(() => {
    if (!version?.id_cenario_teste || !isOpen) {
      return;
    }

    api.get(`/scenario/${version.id_cenario_teste}`).then(response => {
      setScenario(response.data);
    });
  }, [version?.id_cenario_teste, isOpen]);

  useEffect(() => {
    if (!version_id || !isOpen) {
      return () => null;
    }

    api.get(`steps/${version_id}`).then(response => {
      setSteps(response.data);
    });

    return () => {
      setVersion(undefined);
      setSteps([]);
    };
  }, [version_id, setSteps, isOpen]);

  return (
    <Modal isOpen={isOpen} width={800} title="Visualizar cenário">
      <Description>Nome</Description>

      <p>{scenario.descr_cenario}</p>

      <Description>Condição</Description>

      <p>{scenario.descr_condicao}</p>

      <Description>Resultado esperado</Description>

      <p>{scenario.descr_resultado_esperado}</p>

      {version && (
        <EditServiceOrderModalSecondary
          isOpen={isEditModalOpen}
          toggleOpen={toggleEditModalOpen}
          version={version}
          setVersion={setVersion}
          setSelectedVersion={setVersion}
        />
      )}

      <Line />

      <Content>
        <header>
          <Subtitle>Ordem de serviço {version?.nro_ordem}</Subtitle>

          <div>
            <BaseButton model="edit" onClick={toggleEditModalOpen} text />
          </div>
        </header>

        <Description>Status</Description>
        <div style={{ display: 'flex' }}>
          {version?.status === 'A' && (
            <>
              <FiCheck color="#55a630" size={20} />
              <span>Aprovado</span>
            </>
          )}
          {version?.status === 'R' && (
            <>
              <FiX color="#e63946" size={20} />
              <span>Reprovado</span>
            </>
          )}
          {version?.status === 'P' && (
            <>
              <IoHelp color="#f4a261" size={20} />
              <span>Pendente</span>
            </>
          )}
        </div>

        <Description>Requer atualização no testes automatizados?</Description>
        <div style={{ display: 'flex' }}>
          {version?.exige_atualizacao_teste === 'S' && <p>Sim</p>}
          {version?.exige_atualizacao_teste === 'N' && <p>Não</p>}
        </div>
      </Content>
      <Line />
      <Subtitle>Etapas</Subtitle>
      {steps?.map((step, index) => (
        <Step
          key={step.id}
          step={step}
          index={index}
          viewMode={false}
          isEditing={
            !!isEditingSteps.find(editingStep => editingStep.id === step.id)
          }
          setIsEditingSteps={setIsEditingSteps}
        />
      ))}

      <Form ref={newStepFormRef} onSubmit={handleAddNewStep}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input name="descr_etapa" placeholder="Descrição da nova etapa" />
          <BaseButton
            type="submit"
            model="plus"
            style={{ marginLeft: '10px', marginTop: 0 }}
            text={false}
          />
        </div>
      </Form>
      <Line />
      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />

        <Link to={`/problems/${version?.id}`}>
          <BaseButton model="problem" text />
        </Link>
      </Buttons>
    </Modal>
  );
}
