import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

import { appearFromOpacity, COLORS } from 'styles';

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 350px;
  animation: ${appearFromOpacity} 1.5s;
  margin: 50px auto;

  div + strong {
    margin-top: 20px;
  }

  span,
  strong {
    width: 100%;
    padding: 5px 0;
    text-align: left;
  }

  span {
    padding-bottom: 10px;
  }
`;

export const AvatarInput = styled.div`
  display: flex;
  position: relative;
  width: 182px;
  height: 182px;
  align-items: center;
  justify-content: center;

  border: 4px solid ${COLORS.ACCENT};
  border-radius: 50%;

  img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
  }
`;
