import ChartHorasServico from 'components/ChartHorasServico/index.jsx';
import ChartPrazoEntrega from 'components/ChartPrazoEntrega/index.jsx';
import ChartQuantidadeBug from 'components/ChartQuantidadeBug/index.jsx';
import ChartSubcontainer from 'components/ChartSubcontainer/index.jsx';

import { useColaboradores } from 'hooks/colaboradores.jsx';
import { useSolucoes } from 'hooks/solucoes.jsx';
import { useVersoes } from 'hooks/versoes.jsx';
import { useEffect } from 'react';
import { api } from 'services/api';
import { Container } from './styles.jsx';

export function Metricas() {
  const { setSolucoes, solucoes } = useSolucoes();
  const { setVersoes, versoes } = useVersoes();
  const { setColaboradores, colaboradores } = useColaboradores();

  useEffect(() => {
    document.title = 'Métricas de Software - Test Soft';

    if (solucoes.length === 0) {
      api
        .get('solucoes')
        .then(response => {
          setSolucoes(response.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
    if (colaboradores.length === 0) {
      api
        .get('colaboradores')
        .then(response => {
          setColaboradores(response.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
    if (versoes.length === 0) {
      api
        .get('versoes')
        .then(response => {
          setVersoes(response.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  return (
    <Container>
      <ChartSubcontainer>
        <ChartPrazoEntrega
          style={{
            marginLeft: '5px',
            marginBottom: '5px',
            marginTop: '5px',
            marginRight: '2.5px',
          }}
          title="Solicitações fora do prazo de entrega"
        />

        <ChartQuantidadeBug
          style={{
            marginRight: '5px',
            marginBottom: '5px',
            marginTop: '5px',
            marginLeft: '2.5px',
          }}
          title="Quantidade de bugs lançados"
        />
      </ChartSubcontainer>
      <ChartSubcontainer>
        <ChartHorasServico
          style={{ marginLeft: '5px', marginRight: '5px', marginBottom: '5px' }}
          title="Quantidade de horas por tipo de demanda"
        />
      </ChartSubcontainer>
    </Container>
  );
}
