import styled from 'styled-components';

export const Title = styled.strong`
  display: flex;
  margin: 0 0 15px 0;
  color: #fff;
`;

export const Content = styled.div`
  padding: 5px;
  color: #fff;

  word-break: break-all;
  & + div {
    margin-top: 10px;
  }

  > span {
    color: #dddd;
  }
`;

export const Subtitle = styled.strong`
  display: flex;
  padding: 10px 0 5px 0;
  font-size: 14px;
  color: #fff;

  & + p {
    padding: 0 0 10px 0;
  }
`;
