/* eslint-disable import/no-extraneous-dependencies */
import styled from 'styled-components';

import { Filter } from '@styled-icons/fa-solid';
import { FilePdf } from '@styled-icons/icomoon';
import { Microsoftexcel } from '@styled-icons/simple-icons/';
import { KeyboardBackspace } from '@styled-icons/material/';

export const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.46) 0px 1px 4px;
  border-radius: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ContainerGrafico = styled.div`
  flex: 1;
  width: 100%;
`;

export const ContainerGraficoChild = styled.div`
  position: 'relative';
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ChartWrapper = styled.div`
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
`;

export const TitleWrapper = styled.div`
  display: flex;
  padding-top: 5px;
`;

export const ReportDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  margin-top: 5px;
`;

export const ReportDetailInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const ReportDetailTotal = styled.div`
  display: flex;
`;

export const ButtonDetail = styled.div`
  display: flex;
  outline: none;
  background: none;
  color: white;
  border-radius: 5px;
  padding-right: 10px;
  border: none;
  transition: background 0.4s;
  margin: 0;
  &&:hover {
    cursor: pointer;
    background: #e87b20;
  }
`;

export const ButtonGoBackReport = styled(KeyboardBackspace)`
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  width: 26px;
  height: 26px;
  padding: 5px;
  transition: background 0.4s;
  cursor: pointer;
  border-radius: 5px;

  &&:hover {
    background: #e87b20;
  }
`;

export const ButtonFilter = styled(Filter)`
  margin-left: 10px;
  color: white;
  width: 26px;
  height: 26px;
  padding: 5px;
  transition: background 0.4s;
  cursor: pointer;
  border-radius: 5px;

  &&:hover {
    background: #e87b20;
  }
`;

export const ButtonReportPdf = styled(FilePdf)`
  margin-left: 10px;
  color: white;
  width: 26px;
  height: 26px;
  padding: 5px;
  transition: background 0.4s;
  cursor: pointer;
  border-radius: 5px;

  &&:hover {
    background: #e87b20;
  }
`;

export const ButtonReportExcel = styled(Microsoftexcel)`
  margin-left: 10px;
  color: white;
  width: 26px;
  height: 26px;
  padding: 5px;
  transition: background 0.4s;
  cursor: pointer;
  border-radius: 5px;

  &&:hover {
    background: #e87b20;
  }
`;

export const Component = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
