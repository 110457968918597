import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { BaseButton, Input, Modal, Subtitle, Line } from 'components';

import { api } from 'services';

import { Modulo } from 'types';

import { getValidationErrors } from 'utils';

import { Buttons } from 'components/Modal/styles';

import { Title } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  module: Modulo;
  setModules: React.Dispatch<React.SetStateAction<Modulo[]>>;
  setSelectedModule: React.Dispatch<React.SetStateAction<Modulo | undefined>>;
}

interface EditModuleFormData {
  descr_modulo: string;
}

export function EditModuleModal({
  isOpen,
  toggleOpen,
  module,
  setModules,
  setSelectedModule,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: EditModuleFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_modulo: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/module/${module.id}`, data);

        setSelectedModule(response.data);

        setModules(modules =>
          modules.map(mappedModule =>
            mappedModule.id === response.data.id ? response.data : mappedModule,
          ),
        );

        toggleOpen();

        toast.success('Módulo atualizado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho!');

          return;
        }

        toast.error('Falha na alteração do módulo!');
      }
    },
    [setModules, toggleOpen, module, setSelectedModule],
  );

  return (
    <Modal isOpen={isOpen}>
      <Title>Editar módulo</Title>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={module}>
        <Subtitle>* Nome do Módulo</Subtitle>
        <Input name="descr_modulo" placeholder="Nome do módulo" />
        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="check" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
