import { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import {
  BaseButton,
  Input,
  Modal,
  SelectWithUnform as Select,
  Line,
} from 'components';

import { api } from 'services';

import { OrdemServico } from 'types';

import { useVersions } from 'hooks';

import { getValidationErrors } from 'utils';

import { Buttons } from 'components/Modal/styles';

import { Title, Subtitle } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  scenario_id: number;
  setSelectedVersion: React.Dispatch<
    React.SetStateAction<OrdemServico | undefined>
  >;
}

interface CreateServiceOrderFormData {
  nro_ordem: string;
  exige_atualizacao_teste: 'S' | 'N';
}

export function CreateServiceOrderModal({
  isOpen,
  toggleOpen,
  scenario_id,
  setSelectedVersion,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);

  const { setVersions } = useVersions();

  const handleSubmit = useCallback(
    async (data: CreateServiceOrderFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nro_ordem: Yup.number().required(),
          exige_atualizacao_teste: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const res = await api.post('/version', {
          nro_ordem: data.nro_ordem,
          id_cenario_teste: scenario_id,
          exige_atualizacao_teste: data.exige_atualizacao_teste,
        });

        setSelectedVersion(res.data);

        const response = await api.get(`/versions/${scenario_id}`);

        setVersions(response.data);

        toggleOpen();

        toast.success('Ordem de serviço criada com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error(
          err.response.data.message || 'Falha na criação da ordem de serviço!',
        );
      }
    },
    [setVersions, toggleOpen, scenario_id, setSelectedVersion],
  );

  return (
    <Modal isOpen={isOpen}>
      <Title>Registrar ordem de serviço</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Subtitle>* Número da ordem de serviço</Subtitle>
        <Input
          name="nro_ordem"
          placeholder="Código da ordem de serviço"
          type="number"
        />

        <Subtitle>* Requer atualização no testes automatizados?</Subtitle>
        <Select
          name="exige_atualizacao_teste"
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          options={[
            { label: 'Sim', value: 'S' },
            { label: 'Não', value: 'N' },
          ]}
          isSearchable
        />

        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="plus" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
