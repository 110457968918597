import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import './styles.scss';

type LoadingProps = {
  color?: string;
};

export function Loading({ color, ...rest }: LoadingProps) {
  return (
    <div className="loading-container" {...rest}>
      <AiOutlineLoading3Quarters className="loading-icon" color={color} />
    </div>
  );
}
