import React, { useCallback } from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { Modal, BaseButton, Line } from 'components';

import { api } from 'services';

import { useScreens } from 'hooks';

import { Buttons } from 'components/Modal/styles';

import './styles.scss';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  screenId: number;
};

export function DeleteScreenModal({
  isOpen,
  toggleOpen,
  screenId,
}: ModalProps) {
  const { screens, setScreens } = useScreens();

  const deleteModule = useCallback(async () => {
    try {
      await api.delete(`/screen/${screenId}`);

      setScreens(
        produce(screens, drafts =>
          drafts.filter(draft => draft.id !== screenId),
        ),
      );

      toggleOpen();

      toast.success('Tela deletada com sucesso!');
    } catch (err) {
      toast.error(err.response.data.message || 'Falha ao deletar tela!');
    }
  }, [screens, toggleOpen, setScreens, screenId]);

  return (
    <Modal isOpen={isOpen} title="Atenção">
      <div className="delete-screen">
        <p>Tem certeza que deseja excluir este registro?</p>
      </div>

      <Line />

      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />
        <BaseButton model="check" onClick={deleteModule} text />
      </Buttons>
    </Modal>
  );
}
