const COLORS = {
  WHITE: '#FFFFFF',
  BACKGROUND: '#241F2A',
  BACKGROUND_LIGHT: '#3E3A4D',
  BACKGROUND_DARK: '#1D1922',
  ALERT: '#FF4500',
  ACCENT: '#F57E20',
  FONT: '#EFEFEF',
  FONT_ACCENT: '#F57E20',
  FONT_SECONDARY: '#173E6C',
  FONT_DARK: '#173E6C',
  FONT_LIGHT: '#BFBFBF',
  LIGHTEN_15: 'rgba(255,255,255,0.15)',
  SUCCESS: '#00c332',
  WARNING: '#D6872B',
  YELLOW: '#FFD166',
  BLUE: '#1282A2',
  GREY: '#d4d4d4',
};

export { COLORS };
