import { ValidationError } from 'yup';

interface Errors {
  [key: string]: string;
}

function getValidationErrors(err: ValidationError): Errors {
  const validationsErrors: Errors = {};

  err.inner.forEach(error => {
    validationsErrors[error.path] = error.message;
  });
  return validationsErrors;
}

export { getValidationErrors };
