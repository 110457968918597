import React, { useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

import { LargeButton, Input } from 'components';

import { api } from 'services';

import { getValidationErrors } from 'utils';

import { COLORS } from 'styles';

import { Container } from './styles';

interface ForgotPasswordFormData {
  email: string;
}

export function ForgotPassword() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  useEffect(() => {
    document.title = 'Esqueci minha senha - Test Soft';
  }, []);

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().required().email(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/password/forgot', {
          email: data.email,
        });

        toast.success('Recuperação de senha enviada com sucesso!');

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho.');

          return;
        }

        toast.error('Erro ao enviar recuperação de senha.');
      }
    },
    [history],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <h1>Recuperação de senha</h1>

        <Input name="email" placeholder="E-mail" />

        <LargeButton type="submit" color={COLORS.ACCENT} title="Enviar" />

        <span>
          <Link to="/">Voltar</Link>
        </span>
      </Form>
    </Container>
  );
}
