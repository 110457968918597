import { FormHandles } from '@unform/core';
import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import {
  BaseButton,
  Modal,
  Textarea,
  SelectWithUnform,
  Line,
} from 'components';

import { api } from 'services';

import { Defeito } from 'types';

import {
  getValidationErrors,
  getDefaultGravityOption,
  getDefaultStatusOption,
  getStepsOptions,
  getDefaultStepOption,
} from 'utils';

import { useSteps } from 'hooks';

import { Buttons } from 'components/Modal/styles';

import { Title, Subtitle } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  problem: Defeito;
  setProblems: React.Dispatch<React.SetStateAction<Defeito[]>>;
  setSelectedProblem: React.Dispatch<React.SetStateAction<Defeito | undefined>>;
}

interface EditProblemFormData {
  descr_defeito: string;
  gravidade: 'A' | 'M' | 'B';
  status: 'A' | 'R' | 'N';
  id_etapa_cenario_teste?: string;
  descr_retorno: string;
}

export function EditProblemModal({
  isOpen,
  toggleOpen,
  problem,
  setProblems,
  setSelectedProblem,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);
  const { steps } = useSteps();

  const handleSubmit = useCallback(
    async (data: EditProblemFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_defeito: Yup.string().required(),
          gravidade: Yup.string().required(),
          status: Yup.string().required(),
          id_etapa_cenario_teste: Yup.string().optional(),
          descr_retorno: Yup.string().optional(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/problem/${problem.id}`, {
          descr_defeito: data.descr_defeito,
          gravidade: data.gravidade,
          status: data.status,
          id_etapa_cenario_teste: data.id_etapa_cenario_teste || null,
          descr_retorno: data.descr_retorno,
        });

        setSelectedProblem(response.data);

        setProblems(state =>
          state.map(mappedProblem =>
            mappedProblem.id === problem.id ? response.data : mappedProblem,
          ),
        );

        toggleOpen();

        toast.success('Problema atualizado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho!');

          return;
        }

        toast.error('Falha na alteração do problema!');
      }
    },
    [setProblems, toggleOpen, problem.id, setSelectedProblem],
  );

  const defaultGravityOption = useMemo(() => {
    return getDefaultGravityOption(problem);
  }, [problem]);

  const defaultStatusOption = useMemo(() => {
    return getDefaultStatusOption(problem);
  }, [problem]);

  const stepsOptions = useMemo(() => getStepsOptions(steps), [steps]);

  const defaultStepValue = useMemo(
    () =>
      getDefaultStepOption(
        steps,
        problem.id_etapa_cenario_teste
          ? problem.id_etapa_cenario_teste
          : undefined,
      ),
    [problem.id_etapa_cenario_teste, steps],
  );

  return (
    <Modal isOpen={isOpen} width={600}>
      <Title>Editar problema</Title>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={problem}>
        <Subtitle>* Descrição</Subtitle>
        <Textarea name="descr_defeito" />

        <Subtitle>* Gravidade</Subtitle>
        <SelectWithUnform
          name="gravidade"
          options={[
            { label: 'Alta', value: 'A' },
            { label: 'Média', value: 'M' },
            { label: 'Baixa', value: 'B' },
          ]}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          defaultValue={defaultGravityOption}
          isSearchable
        />

        <Subtitle>* Status</Subtitle>
        <SelectWithUnform
          name="status"
          defaultValue={defaultStatusOption}
          options={[
            { label: 'Aprovado', value: 'A' },
            { label: 'Reprovado', value: 'R' },
            { label: 'Pendente', value: 'P' },
          ]}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          isSearchable
        />

        <Subtitle>Passo</Subtitle>
        <SelectWithUnform
          name="id_etapa_cenario_teste"
          options={stepsOptions}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          defaultValue={defaultStepValue}
          isSearchable
          isClearable
        />

        <Subtitle>Observação do desenvolvedor</Subtitle>
        <Textarea name="descr_retorno" />

        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="check" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
