/* eslint-disable import/no-duplicates */

import React, { useCallback, useEffect, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { Higher, NavigationTree, Page, BaseButton } from 'components';

import { api } from 'services';

import { useAuth, useProblems, useScenarios, useVersions } from 'hooks';

import { Modulo, Projeto, Cenario, Programa } from 'types';

import { getDefaultStatusOption } from 'utils';
import { ViewScenarioModal } from './ViewScenarioModal';

import { CreateScenarioModal } from './CreateScenarioModal';

import { EditScenarioModal } from './EditScenarioModal';

import { DeleteScenarioModal } from './DeleteScenarioModal';
import { DuplicateScenarioModal } from './DuplicateScenarioModal';

interface Params {
  id: string;
}

export function Scenarios() {
  const { id } = useParams<Params>();

  const [loadingScenarios, setLoadingScenarios] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [loadingModule, setLoadingModule] = useState(true);
  const [loadingProject, setLoadingProject] = useState(true);

  const [screen, setScreen] = useState<Programa>({} as Programa);
  const [project, setProject] = useState<Projeto>({} as Projeto);
  const [module, setModule] = useState<Modulo>({} as Modulo);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [selectedScenario, setSelectedScenario] = useState<Cenario | undefined>(
    undefined,
  );

  const { signOut } = useAuth();

  const { scenarios, setScenarios } = useScenarios();
  const { versions } = useVersions();
  const { problems } = useProblems();

  const toggleViewModalOpen = useCallback(() => {
    setViewModalOpen(state => !state);
  }, []);
  const toggleCreateModalOpen = useCallback(() => {
    setCreateModalOpen(state => !state);
  }, []);

  const toggleEditModalOpen = useCallback(() => {
    setEditModalOpen(state => !state);
  }, []);

  const toggleDuplicateModalOpen = useCallback(() => {
    setDuplicateModalOpen(state => !state);
  }, []);

  const toggleDeleteModalOpen = useCallback(() => {
    setDeleteModalOpen(state => !state);
  }, []);

  useEffect(() => {
    if (!module.id_projeto_cenario_teste) {
      return;
    }

    api.get(`/project/${module.id_projeto_cenario_teste}`).then(response => {
      setProject(response.data);
      setLoadingProject(false);
    });
  }, [module.id_projeto_cenario_teste]);

  useEffect(() => {
    if (!screen.id_modulo_cenario_teste) {
      return;
    }

    api.get(`/module/${screen.id_modulo_cenario_teste}`).then(response => {
      setModule(response.data);
      setLoadingModule(false);
    });
  }, [screen.id_modulo_cenario_teste]);

  useEffect(() => {
    document.title = 'Cenários - Test Soft';

    api
      .get(`/screen/${id}`)
      .then(response => {
        setScreen(response.data);
        setLoadingScreen(false);
      })
      .catch(err => {
        if (err.response?.data.newTokenRequired) {
          toast.error('Sessão expirada!');
          signOut();
        }
      });
  }, [id, signOut]);

  useEffect(() => {
    api.get(`/scenarios/${id}`).then(response => {
      setScenarios(response.data);
      setLoadingScenarios(false);
    });
  }, [id, setScenarios, versions, problems]);

  return (
    <Page
      loading={
        loadingProject || loadingModule || loadingScreen || loadingScenarios
      }
    >
      <Higher title="Cenários" />

      {project.descr_projeto && (
        <NavigationTree>
          <Link to={`/modules/${project.id}`}>
            <span>{project.descr_projeto}</span>
          </Link>
          <FiChevronRight />
          <Link to={`/screens/${module.id}`}>
            <span>{module.descr_modulo}</span>
          </Link>
          <FiChevronRight />
          <Link to={`/scenarios/${screen.id}`}>
            <span>{screen.cod_programa}</span>
          </Link>
        </NavigationTree>
      )}

      <CreateScenarioModal
        screenId={id}
        isOpen={createModalOpen}
        toggleOpen={toggleCreateModalOpen}
        setSelectedScenario={setSelectedScenario}
      />

      {selectedScenario && (
        <>
          <ViewScenarioModal
            scenario={selectedScenario}
            isOpen={viewModalOpen}
            toggleOpen={toggleViewModalOpen}
          />

          <EditScenarioModal
            isOpen={editModalOpen}
            toggleOpen={toggleEditModalOpen}
            scenario={selectedScenario}
            setSelectedScenario={setSelectedScenario}
          />

          <DuplicateScenarioModal
            isOpen={duplicateModalOpen}
            selectedScenario={selectedScenario}
            setSelectedScenario={setSelectedScenario}
            toggleOpen={toggleDuplicateModalOpen}
          />

          <DeleteScenarioModal
            isOpen={deleteModalOpen}
            toggleOpen={toggleDeleteModalOpen}
            scenarioId={selectedScenario.id}
            setSelectedScenario={setSelectedScenario}
          />
        </>
      )}

      <div className="tools-buttons">
        <BaseButton
          model="eye"
          text
          onClick={toggleViewModalOpen}
          disabled={!selectedScenario}
        />

        <BaseButton model="plus" text onClick={toggleCreateModalOpen} />

        <BaseButton
          model="edit"
          text
          onClick={toggleEditModalOpen}
          disabled={!selectedScenario}
        />

        <BaseButton
          model="duplicate"
          text
          onClick={toggleDuplicateModalOpen}
          disabled={!selectedScenario}
        />

        <BaseButton
          model="delete"
          text
          onClick={toggleDeleteModalOpen}
          disabled={!selectedScenario}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Nome do cenário</th>
              <th>Pré-condição do cenário</th>
              <th>Resultado esperado</th>
              <th>Status</th>
              <th>Atualizado há</th>
            </tr>
          </thead>

          <tbody>
            {scenarios.map(scenario => (
              <tr
                key={scenario.id}
                onClick={() => {
                  if (selectedScenario?.id === scenario.id) {
                    setSelectedScenario(undefined);
                  } else {
                    setSelectedScenario(scenario);
                  }
                }}
                onDoubleClick={() => {
                  setSelectedScenario(scenario);

                  toggleEditModalOpen();
                }}
                className={
                  scenario.id === selectedScenario?.id
                    ? 'selected-row'
                    : 'not-selected-row'
                }
              >
                <td>{scenario.descr_cenario}</td>
                <td>{scenario.descr_condicao}</td>
                <td>{scenario.descr_resultado_esperado}</td>
                <td
                  style={{
                    color:
                      (scenario.status === 'A' && 'var(--success)') ||
                      (scenario.status === 'R' && 'var(--alert)') ||
                      'var(--accent)',
                  }}
                >
                  {getDefaultStatusOption(scenario)?.label || 'Não definido'}
                </td>
                <td>
                  {scenario.data_atualizacao &&
                    formatDistance(
                      new Date(),
                      new Date(scenario.data_atualizacao),
                      {
                        locale: ptBR,
                      },
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {scenarios.length <= 0 && (
          <p style={{ marginTop: 30 }}>Não há registros cadastrados!</p>
        )}
      </div>
    </Page>
  );
}
