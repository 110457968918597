import ReactSelect, { OptionTypeBase, Props, Theme } from 'react-select';

type SelectProps = Props<OptionTypeBase>;

export function Select({ ...rest }: SelectProps) {
  return (
    <ReactSelect
      theme={(theme: Theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#000',
          primary50: '#111',
        },
      })}
      classNamePrefix="select"
      {...rest}
    />
  );
}
