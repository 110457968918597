/* eslint-disable no-param-reassign */
export const getReadableDate = s => {
  const a = s.substring(5, 7);
  const b = s.substring(8);
  return `${b}/${a}`;
};

export const anos = [
  2021,
  2020,
  2019,
  2018,
  2017,
  2016,
  2015,
  2014,
  2013,
  2012,
  2011,
  2010,
  2009,
  2008,
  2007,
  2006,
  2005,
  2004,
  2003,
  2002,
  2001,
  2000,
];

export const solucoes = [
  'Akita Soft',
  'Akita Mobile',
  'Ponto Express',
  'Ponto Enterprise',
  'Ponto Mobile',
  'GT Soft',
];
/*
export const colaboradores = [
  "Bernardo Barth",
  "Eliéser Prichua",
  "Felipe Dietrich",
  "Geison da Silva",
  "Guilherme Sagaz",
  "Gustavo Harff",
  "Gustavo Siqueira",
  "João Hartmann",
  "Luciano Dietrich",
  "Marcos Koch",
  "Mateus Roerig",
  "Richard Altmayer",
  "Rodrigo Moraes",
  "Vinícius Mello",
]; */

export const colaboradores = [
  'Alfa',
  'Bravo',
  'Romeu',
  'Charlie',
  'Fulano',
  'Ciclano',
  'Beltrano',
];

export const versoes = [
  '6.2.0.0',
  '6.2.0.1',
  '6.2.0.2',
  '6.2.0.3',
  '6.2.0.4',
  '6.2.0.5',
];

export function timeConvert(d) {
  d = Number(d);
  d *= 60;
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);

  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
}

export function getMes(v) {
  if (!v) return '';
  const meses = [
    { nome: 'Janeiro', num: '01' },
    { nome: 'Fevereiro', num: '02' },
    { nome: 'Março', num: '03' },
    { nome: 'Abril', num: '04' },
    { nome: 'Maio', num: '05' },
    { nome: 'Junho', num: '06' },
    { nome: 'Julho', num: '07' },
    { nome: 'Agosto', num: '08' },
    { nome: 'Setembro', num: '09' },
    { nome: 'Outubro', num: '10' },
    { nome: 'Novembro', num: '11' },
    { nome: 'Dezembro', num: '12' },
  ];

  let ret = '';
  meses.forEach(mes => {
    if (v.startsWith(mes.num)) {
      ret = mes.nome;
    }
  });

  return ret;
}
