import React, { useCallback, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { LargeButton, Input, Logo } from 'components';

import { api } from 'services';

import { getValidationErrors } from 'utils';

import { COLORS } from 'styles';
import { Container } from './styles';

interface SignUpFormData {
  nome_usuario: string;
  email: string;
  senha: string;
}

export function SignUp() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  useEffect(() => {
    document.title = 'Registrar - Test Soft';
  }, []);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome_usuario: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um endereço válido'),
          senha: Yup.string()
            .required('Senha obrigatória')
            .min(4, 'Mínimo 4 dígitos'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/users', data);

        toast.success('Conta cadastrada com sucesso!');

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error('Falha no cadastro!');
      }
    },
    [history],
  );

  return (
    <Container>
      <Logo />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input name="nome_usuario" placeholder="Nome" />

        <Input name="email" placeholder="E-mail" />

        <Input name="senha" type="password" placeholder="Senha" />

        <LargeButton type="submit" color={COLORS.ACCENT} title="Cadastrar" />

        <span>
          Já tem uma conta?<Link to="/">Voltar</Link>
        </span>
      </Form>
    </Container>
  );
}
