import { shade } from 'polished';
import styled from 'styled-components';
import { COLORS } from 'styles';

export const Programs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 200px;

  button {
    align-items: center;
    display: flex;
    color: ${COLORS.FONT};
    cursor: pointer;
    padding: 5px;
    background: ${COLORS.BACKGROUND_DARK};
    border-radius: 3px;
    border: 0;
    transition: 0.2s background-color;

    span + span {
      width: 100%;
      margin-left: 10px;
      text-align: right;
    }

    & + button {
      margin-top: 5px;
    }

    &:hover {
      background: ${shade(0.2, COLORS.BACKGROUND_DARK)};
    }
  }
`;
