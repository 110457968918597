import React, { useMemo } from 'react';
import { parseISO, format } from 'date-fns';

import { BaseButton, Modal, Line } from 'components';

import { Defeito } from 'types';

import { useSteps } from 'hooks';

import { Buttons } from 'components/Modal/styles';

import { Title, Content, Subtitle } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  problem: Defeito;
}

export function ViewProblemModal({ isOpen, toggleOpen, problem }: ModalProps) {
  const { steps } = useSteps();

  const status = useMemo(() => {
    switch (problem.status) {
      case 'A': {
        return 'Aprovado';
      }
      case 'P': {
        return 'Pendente';
      }
      case 'R': {
        return 'Reprovado';
      }
      default: {
        return 'undefined';
      }
    }
  }, [problem.status]);

  const gravity = useMemo(() => {
    switch (problem.gravidade) {
      case 'A': {
        return 'Alta';
      }
      case 'B': {
        return 'Baixa';
      }
      case 'M': {
        return 'Média';
      }
      default: {
        return 'undefined';
      }
    }
  }, [problem.gravidade]);

  const step = useMemo(() => {
    return steps.find(
      filteredStep => filteredStep.id === problem.id_etapa_cenario_teste,
    );
  }, [problem.id_etapa_cenario_teste, steps]);

  return (
    <Modal isOpen={isOpen} width={600}>
      <Content>
        <Title>Visualizar problema</Title>

        <Subtitle>Descrição</Subtitle>
        <span>{problem.descr_defeito}</span>

        <Subtitle>Gravidade</Subtitle>
        <span>{gravity}</span>

        <Subtitle>Status</Subtitle>
        <span>{status}</span>

        <>
          <Subtitle>Passo</Subtitle>
          <span>
            {step?.descr_etapa
              ? `${step.ordem_etapa}: ${step?.descr_etapa}`
              : 'Não definido'}
          </span>
        </>

        <>
          <Subtitle>Observação do desenvolvedor</Subtitle>
          <span>
            {problem.descr_retorno ? problem.descr_retorno : 'Não definido'}
          </span>
        </>

        <Line />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {problem.data_criacao && (
            <div>
              <Subtitle>Criado em</Subtitle>
              <span>
                {format(parseISO(problem.data_criacao), 'dd/MM/yyyy HH:mm')}
              </span>
            </div>
          )}

          {problem.data_atualizacao && (
            <div>
              <Subtitle>Atualizado em</Subtitle>
              <span>
                {format(parseISO(problem.data_atualizacao), 'dd/MM/yyyy HH:mm')}
              </span>
            </div>
          )}
        </div>
      </Content>

      <Line />

      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />
      </Buttons>
    </Modal>
  );
}
