import { useEffect } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Page, Higher } from 'components';

import { GoBack } from './styles';

export function NotFound() {
  useEffect(() => {
    document.title = 'Página não encontrada - Test Soft';
  }, []);

  return (
    <Page>
      <Higher title="Página não encontrada!" />

      <GoBack>
        <Link to="/projects">
          <div>
            <FiChevronLeft />
            <span>Voltar a página inicial</span>
          </div>
        </Link>
      </GoBack>
    </Page>
  );
}
