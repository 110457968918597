import React, { useCallback } from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { api } from 'services';

import { Modal, BaseButton, Line } from 'components';

import { Projeto } from 'types';

import { Buttons } from 'components/Modal/styles';

import './styles.scss';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  projectId: number;
  setProjects: React.Dispatch<React.SetStateAction<Projeto[]>>;
  setSelectedProject: React.Dispatch<React.SetStateAction<Projeto | undefined>>;
};

export function DeleteProjectModal({
  isOpen,
  toggleOpen,
  projectId,
  setProjects,
  setSelectedProject,
}: ModalProps) {
  const deleteProject = useCallback(async () => {
    try {
      await api.delete(`/project/${projectId}`);

      setProjects(state =>
        produce(state, drafts =>
          drafts.filter(draft => draft.id !== projectId),
        ),
      );

      setSelectedProject(undefined);

      toggleOpen();

      toast.success('Projeto deletado com sucesso!');
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }, [projectId, setProjects, setSelectedProject, toggleOpen]);

  return (
    <Modal isOpen={isOpen} title="Atenção">
      <div className="delete-project">
        <p>Tem certeza que deseja excluir este registro?</p>
      </div>
      <Line />
      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />
        <BaseButton model="check" onClick={deleteProject} text />
      </Buttons>
    </Modal>
  );
}
