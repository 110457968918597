import React from 'react';

function ChartCustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    return <div />;
  }
  return null;
}

export default ChartCustomTooltip;
