import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { LargeButton, Input, Logo, MicrosoftButton } from 'components';

import { useAuth } from 'hooks';

import { getValidationErrors } from 'utils';

import { COLORS } from 'styles';

import { Container } from './styles';

interface SignInFormData {
  email: string;
  senha: string;
}

export function SignIn() {
  const formRef = useRef<FormHandles>(null);
  const { signIn, microsoftSignIn } = useAuth();

  const [loadingMicrosoft, setLoadingMicrosoft] = useState(false);

  const history = useHistory();

  useEffect(() => {
    document.title = 'Login - Test Soft';
  }, []);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um endereço válido'),
          senha: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        await signIn({
          email: data.email,
          senha: data.senha,
        });

        history.push('/projects');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error('Falha na autenticação!');
      }
    },
    [signIn, history],
  );

  const handleLoginMicrosoft = useCallback(async () => {
    try {
      setLoadingMicrosoft(true);
      await microsoftSignIn();
    } catch {
      toast.error('Falha na autenticação!');
    } finally {
      setLoadingMicrosoft(false);
    }
  }, [microsoftSignIn]);

  return (
    <Container>
      <Logo />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input name="email" placeholder="E-mail" />

        <Input name="senha" type="password" placeholder="Senha" />

        <LargeButton type="submit" color={COLORS.ACCENT} title="Entrar" />

        <span style={{ textAlign: 'center' }}>ou</span>

        <MicrosoftButton
          type="button"
          onClick={handleLoginMicrosoft}
          loading={loadingMicrosoft}
        >
          Entrar com Microsoft
        </MicrosoftButton>

        <span>
          Esqueceu sua senha?<Link to="/forgot">Recuperar senha</Link>
        </span>
      </Form>
    </Container>
  );
}
