import React, { useCallback } from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { api } from 'services';

import { Modal, BaseButton, Line } from 'components';

import { Modulo } from 'types';

import { Buttons } from 'components/Modal/styles';

import './styles.scss';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  moduleId: number;
  setModules: React.Dispatch<React.SetStateAction<Modulo[]>>;
  setSelectedModule: React.Dispatch<React.SetStateAction<Modulo | undefined>>;
};

export function DeleteModuleModal({
  isOpen,
  toggleOpen,
  moduleId,
  setModules,
  setSelectedModule,
}: ModalProps) {
  const deleteModule = useCallback(async () => {
    try {
      await api.delete(`/module/${moduleId}`);

      setModules(state =>
        produce(state, drafts => drafts.filter(draft => draft.id !== moduleId)),
      );

      setSelectedModule(undefined);

      toggleOpen();

      toast.success('Módulo deletado com sucesso!');
    } catch (err) {
      toast.error(err.response.data?.message || 'Error ao deletar módulo');
    }
  }, [moduleId, setModules, setSelectedModule, toggleOpen]);

  return (
    <Modal isOpen={isOpen} title="Atenção">
      <div className="delete-module">
        <p>Tem certeza que deseja excluir este registro?</p>
      </div>
      <Line />
      <Buttons>
        <BaseButton model="close" onClick={toggleOpen} text />
        <BaseButton model="check" onClick={deleteModule} text />
      </Buttons>
    </Modal>
  );
}
