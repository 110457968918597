import { ButtonHTMLAttributes } from 'react';
import { TiVendorMicrosoft } from 'react-icons/ti';

import { Loading } from 'components';

import './styles.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean;
}

export function MicrosoftButton({ loading, ...rest }: ButtonProps) {
  if (loading) {
    return (
      <button
        className="microsoft-button"
        type="button"
        style={{ cursor: 'progress' }}
        disabled
        {...rest}
      >
        <Loading color="#333" />
      </button>
    );
  }

  return (
    <button className="microsoft-button" type="button" {...rest}>
      <TiVendorMicrosoft /> <p>Entrar com Microsoft</p>
    </button>
  );
}
