import { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { BaseButton, Input, Modal, Subtitle, Line } from 'components';

import { api } from 'services';

import { useScenarios } from 'hooks';

import { getValidationErrors } from 'utils';

import { Buttons } from 'components/Modal/styles';

import { Cenario } from 'types';
import { Title } from './styles';

interface ModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
  screenId: string;
  setSelectedScenario: React.Dispatch<
    React.SetStateAction<Cenario | undefined>
  >;
}

interface CreateScenarioFormData {
  descr_cenario: string;
}

export function CreateScenarioModal({
  isOpen,
  toggleOpen,
  screenId,
  setSelectedScenario,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);

  const { setScenarios } = useScenarios();

  const handleSubmit = useCallback(
    async (data: CreateScenarioFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_cenario: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const { data: scenario } = await api.post('/scenarios', {
          descr_cenario: data.descr_cenario,
          id_programa_cenario_teste: screenId,
        });

        setSelectedScenario(scenario);

        const response = await api.get(`scenarios/${screenId}`);
        setScenarios(response.data);

        toggleOpen();

        toast.success('Cenário criado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho!');

          return;
        }

        toast.error('Falha na criação do cenário!');
      }
    },
    [toggleOpen, screenId, setScenarios],
  );

  return (
    <Modal isOpen={isOpen}>
      <Title>Criar cenário</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Subtitle>* Nome do Cenário</Subtitle>
        <Input name="descr_cenario" placeholder="Nome do cenário" />

        <Line />

        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="plus" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
