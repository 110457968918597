import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { BaseButton, Input, Modal, Subtitle, Line } from 'components';

import { api } from 'services';

import { Projeto } from 'types';

import { getValidationErrors } from 'utils';

import { Buttons } from 'components/Modal/styles';

type ModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  setProjects: React.Dispatch<React.SetStateAction<Projeto[]>>;
};

type CreateProjectFormData = {
  descr_projeto: string;
};

export function CreateProjectModal({
  isOpen,
  toggleOpen,
  setProjects,
}: ModalProps) {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: CreateProjectFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descr_projeto: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post('/project', data);

        setProjects(projects => [response.data, ...projects]);

        toggleOpen();

        toast.success('Projeto criado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          toast.error('Verifique os campos em vermelho!');

          return;
        }

        toast.error('Falha na criação do projeto!');
      }
    },
    [setProjects, toggleOpen],
  );

  return (
    <Modal isOpen={isOpen} title="Criar projeto">
      <Subtitle>* Nome do Projeto</Subtitle>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input name="descr_projeto" placeholder="Nome do projeto" />

        <Line />
        <Buttons>
          <BaseButton model="close" onClick={toggleOpen} text />
          <BaseButton model="plus" type="submit" text />
        </Buttons>
      </Form>
    </Modal>
  );
}
