import { OrdemServico } from 'types';

function getStatusOption(serviceOrder: OrdemServico | undefined): string {
  switch (serviceOrder?.status) {
    case 'A':
      return 'Aprovado';
    case 'R':
      return 'Reprovado';
    case 'P':
      return 'Pendente';
    default: {
      return 'undefined';
    }
  }
}

export { getStatusOption };
