import { OrdemServico } from 'types';

interface IResponse {
  value: number;
  label: string;
}

function getServiceOrderOptions(versions: OrdemServico[]): IResponse[] {
  return versions.map(version => {
    let status;
    switch (version.status) {
      case 'A':
        status = '✓';
        break;
      case 'P':
        status = '？';
        break;
      case 'R':
        status = '✕';
        break;
      default:
        status = 'null';
    }

    return { value: version.id, label: `${status} ${version.nro_ordem}` };
  });
}

export { getServiceOrderOptions };
